.cards-container {
    display: flex;
    
    color: #333;
    /* background: #EEEEEE;; */
    padding: 1rem 0rem;
    overflow-x: auto;
    position: relative;
    gap: 20px;
  }
  .cards-container::-webkit-scrollbar {
    display: none;
  }
  
  .card {
    flex: 1 0 250px;
    background: #fff;
    /* margin: 1rem; */
    overflow: hidden;
    /* cursor: pointer; */
    transition: all 250ms ease-in-out;
  }
  .card img {
    width: 250px;
    height: 250px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .card:hover {
    box-shadow: 0 4px 1rem -4px #000;
    transform: translateY(-3px);
  }
  
  .card-content {
    padding: 1rem;
  }
  .cards-container .card h2 {
    font-size: 1rem;
    font-weight: 700;
  }
  .cards-container .card p{
    font-size: 1rem;
  }
  
  @media screen and (max-width: 625px) {
    .container {
      width: 100%;
    }
  
    .cards-container {
      padding: 0.5rem;
    }
  
    /* .card {
      margin: 0.5rem;
    } */
  }