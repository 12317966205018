
.section-img {
  background-image: url('../../assets/Home-page-banner.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
.section-height-home{
  min-height: 56.25vw;
  max-height: 56.25vw;
}

.HeadText-home{
  font-family: 'Poppins';
  position: absolute;
  top: 25vw;
  left: 50%;
  transform: translate(-50%, -50%);
  font-style: normal;
  font-weight: 900;
  font-size: 6rem;
  padding-left: 100px;
  line-height: 1em;  
  align-items: center;
  color: #FFFFFF;
}

.first-home {
  position: absolute;
  top: 51.5vw;
  left: 0;
  width: 85%;
  height: 190px;
  background-color: #0492B1;
}

.first-home-text {
  position: absolute;
  padding: 15px 65px 0px 100px;
}
.first-home-text-size {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
  color: white;
  margin-bottom: 0rem;
}

.SubHeadText-home{
  font-family: 'Poppins';
  position: absolute;
  top: 33vw;
  left: 50%;
  transform: translate(-50%, -50%);
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  padding-left: 100px;
  line-height: 40px;
  align-items: center;
  color: #0492B1;
}

.sectionSecontionHome {
  padding-top: 6rem;
  background-color: #fff;
  padding-bottom: 2rem;
}
.sectionSecontionHome .SubText-home {
  padding: 0px;
}
.SubText-home p{
  word-wrap: break-word;
  text-overflow: clip;
  text-align: left;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #231E28;
  width: 93%;
}
.SubText2-home{
  word-wrap: break-word;
  text-overflow: clip;
  text-align: left;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
  color: #231E28;
}

.whycontentblock {
  width: 70%;
} 
.whyKibbCom{
  background-color: #F6F5F5;
  padding: 3rem;
}
.whyKibbCom h2, .blogSection h2{
  font-weight: 900;
  font-size: 3rem;
}
.whyKibbCom h3{
  font-weight: 700;
  font-size: 1.5rem;
}
.whyKibbCom p {
  font-size: 1rem;
  line-height: 1.5rem;
}
.whyKibbCom .whyKibbcomRow {
  display: grid;
  grid: 12rem / auto auto auto;
  grid-gap: 10rem;
}

.custom-tag2 {
  max-width: 100%;
  height: 600px;
  background-attachment: fixed;
  background-position: top right; 
}

.blogSection {
  background-color: #fff;
  padding: 3rem 1rem 1rem 1rem;
}
.blogSection .slide {
  margin-left: -10px;
}
.blogSection .divwidth {
  position: absolute;
  width: 601px;
  height: 340px;
  left: -185px;
  top: 35px;
  background: #0492B1;
}
.blogSection .caption_text >h2 {
  font-size: 7vh;
  padding-left: 40px;
  padding-top: 100px;
  text-align: left;
  font-family: 'Poppins';
  font-weight: bolder;
  color: #FFFFFF;
}
.blogSection .caption_text>P {
  font-size: 3.2vh;
  padding-left: 42px;
  padding-right: 20px;
  justify-content: left;
  text-align: left;
  font-family: 'Poppins';
  color: #000;
}
.blogSection button.btn {
  margin-left: 42px;
  background-color: #fff;
  border-radius: 20px;
  font-weight: 600;
}
.blogSection button.btn:hover {
  background-color: #fff;
}
.blogSection .imgcal_size{
  width:630px;
  height: 400px;
  position:absolute;
  left: 0px;
  top: 0px;
  bottom: 100px;
}

@media  screen and (min-width: 320px) {
  .HeadText-home {
    top: 15rem;
    font-size: 2.1rem;
    left: 4.8rem;
    line-height: 3rem;
  }
  .SubHeadText-home {
    top: 21rem;
    font-size: 1.2rem;
    padding-top: 0rem;
    left: 4.8rem;
    line-height: 1.5rem;
  }
  .section-img {
    height: 31rem;
    background-repeat: inherit;
  }
  .first-home {
    top: 27rem;
    height: 18.6rem;
    width: 90%;
  }
  .first-home-text {
    left: 0.4rem;
    padding: 1rem 0.5rem 1rem 0.5rem;
  }
  .first-home-text-size {
    font-size: 1rem;
    line-height: 1.6rem;
  }

  .sectionSecontionHome {
    padding-top: 18rem;
  }
  .SubText-home p{
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 0rem 1rem;
  }
  
  .whyKibbCom, .blogSection {
    padding: 3rem 1rem 1rem 1rem;
  }
  .whycontentblock {
    width: 100%;
  } 
  .whyKibbCom h2, .blogSection h2 {
    font-size: 2rem;
  }
  .whyKibbCom h3 {
    font-size: 1.4rem;
  }
  .custom-tag2 {
    height: 550px;
  }

  .blogSection .imgcal_size{
    width:90%;
    height: auto;
  }
  .blogSection .divwidth {
    margin-left: 190px;
    top: -70px;
    left: -165px;
    height: 340px;
  }
  .blogSection .caption_text>p.blogName {
    padding-top: 40px;
    font-size: 2vh;
  }
  .blogSection .caption_text >h2 {
    font-size: 4vh;
    padding-left: 20px;
    padding-top: 15px;
  }
  .blogSection .caption_text>P{
    font-size: 2.3vh;
    padding-left: 20px;
    padding-top: 5px;
    width: 44%;
  }
  .blogSection button.btn {
    margin-left: 20px;
    padding: 5px 25px;
  }
} 
 
@media (min-width: 321px) and (max-width: 480px) {
  .HeadText-home {
    top: 14rem;
    font-size: 2.5rem;
    left: 8.3rem;
  }
  .SubHeadText-home {
    top: 20rem;
    font-size: 1.2rem;
    padding-top: 0rem;
    left: 8.3rem;
    line-height: 1.5rem;
  }
  .section-img {
    height: 30rem;
    background-repeat: inherit;
  }
  .first-home {
    top: 25rem;
    height: 16rem;
  }
  .first-home-text {
    left: 0.8rem;
    padding: 1rem 0.5rem 1rem 0.5rem;
  }
  .first-home-text-size {
    font-size: 1rem;
  }

  .sectionSecontionHome {
    padding-top: 14rem;
  }
  .SubText-home p{
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 0rem 1.5rem;
  }
  
  .whyKibbCom .whyKibbcomRow {
    display: block;
  }
  .whycontentblock {
    width: 100%;
  } 
  .custom-tag2 {
    height: 550px;
  }
  .blogSection .imgcal_size{
    width: 90%;
    height: auto;
  }
  .blogSection .divwidth {
    margin-left: 190px;
    height: 325px;
  }
  .blogSection .caption_text >h2 {
    font-size: 4.5vh;
    padding-left: 20px;
    padding-top: 25px;
  }
  .blogSection .caption_text>P {
    width: 55%;
  }
  .blogSection button.btn {
    margin-left: 20px;
    padding: 5px 35px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  /* Banner welcome text */
  .HeadText-home {
    top: 15rem;
    font-size: 3.5rem;
    left: 20.2rem;
    line-height: 4rem;
  }
  /* Banner sub heading text */
  .SubHeadText-home {
    top: 21rem;
    font-size: 1.4rem;
    padding-top: 0rem;
    left: 20.2rem;
    line-height: 2rem;
  }
  /* Banner overlap text */
  .first-home {
    top: 33rem;
    height: 10rem;
  }
  .first-home-text {
    left: 2rem;
    padding: 1rem 0.5rem 1rem 0.5rem;
  }
  .first-home-text-size {
    font-size: 1rem;
    line-height: 1.6rem;
  }
  .section-img {
    height: 37rem;
    background-repeat: inherit;
  }
  
  /* Second section start */
  .sectionSecontionHome {
    padding-top: 20%
  }
  .SubText-home p{
    font-size: 1rem;
    line-height: 1.5rem;
    margin-left: 1rem;
    padding: 0rem;
  }
  .whycontentblock {
    width: 100%;
  } 
  .custom-tag2 {
    height: 775px;
  }
  .blogSection .imgcal_size{
    width:82%;
  }
  .blogSection .divwidth {
    margin-left: 260px;
    top: 150px;
    height: 310px;
  }
  .blogSection .caption_text >h2 {
    font-size: 4.7vh;
    padding-left: 40px;
    padding-top: 20px;
  }
  .blogSection .caption_text>P{
    font-size: 2.5vh;
    padding-left: 40px;
    padding-top: 5px;
    width: 100%;
  }
  .blogSection button.btn {
    margin-left: 40px;
    padding: 5px 50px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .navbar-menu {
    margin-left: 0px;
  }
  /* Banner welcome text */
  .HeadText-home {
    top: 15rem;
    font-size: 4rem;
    left: 28.8rem;
    line-height: 5rem;
  }
  /* Banner sub heading text */
  .SubHeadText-home {
    top: 22rem;
    font-size: 1.3rem;
    padding-top: 0rem;
    left: 28.8rem;
    line-height: 2rem;
  }
  /* Banner overlap text */
  .first-home {
    top: 33rem;
    height: 8.5rem;
  }
  .first-home-text {
    left: 2.2rem;
    padding: 1rem 1rem 1rem 1rem;
  }
  .first-home-text-size {
    font-size: 1.1rem;
  }
  .section-img {
    height: 37rem;
    background-repeat: inherit;
  }
  
  /* Second section start */
  .sectionSecontionHome {
    padding-top: 8.5rem;
  }
  .SubText-home p{
    font-size: 1.1rem;
    line-height: 1.5rem;
    padding: 0rem 1.1rem;
  }

  .whyKibbCom, .blogSection {
    padding: 3rem 3rem;
  }
  .whycontentblock {
    width: 80%;
  } 
  .whyKibbCom h2, .blogSection h2 {
    font-size: 2.5rem;
  }
  .whyKibbCom h3 {
    font-size: 1.5rem;
  }
  .whyKibbCom p {
    font-size: 1.1rem;
  }
  .custom-tag2 {
    height: 600px;
  }
  .blogSection .imgcal_size{
    width:55%;
  }
  .blogSection .divwidth {
    margin-left: 190px;
    top: 0px;
    left: 240px;
    width: 475px;
    height: 370px;
  }
  .blogSection .caption_text >h2 {
    font-size: 5.6vh;
    padding-left: 40px;
    padding-top: 40px;
  }
  .blogSection .caption_text>P{
    font-size: 2.7vh;
    padding-left: 40px;
    padding-top: 5px;
    width: 100%;
  }
  .blogSection button.btn {
    margin-left: 40px;
    padding: 5px 50px;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .navbar-menu {
    margin-left: 0px;
  }
  .HeadText-home {
    top: 25vw;
    font-size: 2.5rem;
    left: 48%;
  }
  .SubHeadText-home {
    top: 31vw;
    font-size: 1.5rem;
    padding-top: 35px;
    left: 48%;
    line-height: 2rem;
  }
  .section-img {
    height: 600px;
    background-repeat: inherit;
  }
  .first-home {
    top: 34rem;
    height: 6rem;
    width: 85%;
  }
  .first-home-text {
    left: 4.3rem;
    padding: 0.5rem;
  }
  .first-home-text-size {
    font-size: 16px;
  }
  .whycontentblock {
    width: 80%;
  } 
  .sectionSecontionHome {
    padding-top: 6rem;
  }
  .SubText-home p{
    font-size: 17px;
    line-height: 30px;
  }
  .custom-tag2 {
    height: 775px;
  }
  .blogSection .imgcal_size{
    width:100%;
  }
  .blogSection .divwidth {
    margin-left: 190px;
  }
  
}

@media (min-width: 1201px) and (max-width: 1450px) {
  .HeadText-home {
    top: 18rem;
    font-size: 5rem;
    left: 44.3rem;
    line-height: 5rem;
  }
  .SubHeadText-home {
    top: 25rem;
    font-size: 1.6rem;
    padding-top: 0rem;
    left: 44.3rem;
    line-height: 2rem;
  }
  .section-img {
    height: 38rem;
    background-repeat: inherit;
  }
  .first-home {
    top: 34rem;
    height: 7rem;
  }
  .first-home-text {
    left: 0rem;
    padding: 1rem 5rem 0rem 5.4rem;
  }
  .first-home-text-size {
    font-size: 1.2rem;
    /* padding: 10px 30px 0px 65px; */
    line-height: 1.7rem;
  }
  .sectionSecontionHome {
    padding-top: 7rem;
  }
  .whyKibbCom, .blogSection {
    padding: 3rem 5.4rem;
  }
  .SubText-home p{
    font-size: 1.2rem;
    line-height: 1.6rem;
    padding: 0rem 1.7rem;
  }
  .custom-tag2 {
    height: 730px;
  }
  .whyKibbCom h2, .blogSection h2 {
    font-size: 2.8rem;
  }
  .whyKibbCom h3 {
    font-size: 1.8rem;
  }
  .whyKibbCom p {
    font-size: 1.2rem;
  }
  .whycontentblock {
    width: 70%;
  }
  .blogSection p.subHeadingBlog {
    font-size: 20px;
  }
  .blogSection .imgcal_size{
    width: 600px;
  }
  .blogSection .divwidth {
    margin-left: 700px;
    top: 0px;
    height: 450px;
  }
  .blogSection .caption_text >h2 {
    font-size: 4.8vh;
    padding-left: 50px;
    padding-top: 55px;
  }
  .blogSection .caption_text>P{
    font-size: 3vh;
    padding-left: 50px;
    padding-top: 5px;
    width: 100%;
  }
  .blogSection button.btn {
    margin-left: 50px;
    padding: 10px 50px;
  }
}
@media (min-width: 1451px) and (max-width: 2600px) {
  .HeadText-home {
    top: 18rem;
    font-size: 5rem;
    left: 50%;
    line-height: 5rem;
  }
  .SubHeadText-home {
    top: 25rem;
    font-size: 1.5rem;
    padding-top: 1rem;
    left: 50%;
    line-height: 2rem;
  }
  .section-img {
    height: 42rem;
    background-repeat: inherit;
  }
  .first-home {
    top: 38rem;
    height: 7rem;
    width: 83rem;
  }
  .first-home-text {
    left: 0rem;
    padding: 1rem 5rem 0.5rem 6.2rem;
  }
  .first-home-text-size {
    font-size: 1rem;
    letter-spacing: 0rem;
    line-height: 1.7rem;
  }
  .sectionSecontionHome {
    padding-top: 6rem
  }
  .SubText-home p{
    font-size: 1rem;
    line-height: 1.7rem;
    padding: 0rem 0rem;
  }
  .SubText-home p:last-child {
    margin-bottom: 0rem;
  }
  .whycontentblock {
    width: 70%;
  }
  .custom-tag2 {
    height: 40rem;
  }
  .blogSection .imgcal_size{
    width: 38rem;
  }
  .blogSection .divwidth {
    margin-left: 40rem;
    top: -7rem;
    height: 28rem;
  }
  .blogSection .caption_text>p.blogName {
    padding-top: 5rem;
    font-size: 0.9rem;
  }
  .blogSection .caption_text >h2 {
    font-size: 2rem;
    padding-left: 3rem;
    padding-top: 3rem;
  }
  .blogSection .caption_text>P{
    font-size: 1rem;
    padding-left: 3rem;
    padding-top: 0rem;
    width: 100%;
  }
  .blogSection button.btn {
    margin-left: 3rem;
    padding: 0.5rem 3rem;
  }
}