



.navbar-home{
    background: #000;
    height: 60px;
    margin-top: -80px;
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:1rem;
    position: sticky;
    top:0;
    z-index: 10;

}

.navbar-container {    
    display: flex;
    justify-content: space-between;
    height:80px;
    max-width:1500px ;
    z-index:1;
    width:100%;
    padding :0 24px;
}

.navbar-logo {
    color:#fff;
    justify-self:flex-start;
    cursor: pointer;
    font-size:1.5rem;
    display:flex;
    align-items:center;
    margin-left:4vw;
    font-weight:bold;
    text-decoration: none;
}

.mobile-icon{
    display: none;
}

.navbar-menu{
    display: flex;
    align-items: baseline;
    list-style: none;
    text-align: center;
    margin-left: -330px;
}

.navbar-item{
    height: 80px;
}

.navbar-link{
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;

}
.navbar-link.active{
    color: #007afe;
    font-weight: 600;
}
.title-menu-item{
    color: #fff;
    cursor: pointer;
    /* outline: none !important; */
}
.title-menu-item:hover {
    color: #fff !important;
    cursor: pointer;
    /* outline: none !important; */
}
.title-menu-item:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: transparent !important;
  
}
.dropdown-menu-item{
    /* position: absolute;
    inset: 5px auto auto 0px;
    transform: translate3d(0px, 60px, 0px); */
    /* background-color: transparent !important; */
    border-radius: 0px !important;
    border-width: 0px !important;
    color: white !important;
    
}
.dropdown-menu-item::click{
    display: none;
}
.navbar-dropdown-item{
    color: #000 ;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    line-height: 2rem;
}
.dropdown-item {
    --bs-dropdown-link-active-bg: transparent;
}
.dropdown-item:hover {
    color: #007afe;
    background-color: transparent;
}

.navbar-link:hover{
    font-weight: bolder;
    color: #007afe;
}
.nav-btn{
    display: flex;
    align-items:center;
    margin-right: 50px;
}

.nav-btn-link{
    background: #FD5F07;
    white-space:nowrap;
    padding: 18px 22px;
    color: #fff;
    font-size:16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    margin-top: 0px;
}

.nav-btn-link:hover{
    transition: all 0.2s ease-in-out;
    background: #FD5F07;
    color: #fff;
}

.Fasearch{
    margin: 0px 25px 0 0;
    height: 1.2rem;
    width: 1.2rem;
}
@media screen and (max-width: 1024px){ 
    .navbar-logo {
        margin-left: 2vw;
    }
}
@media screen and (max-width: 960px){
    .nav-home{
        transition: 0.8s all ease;

    }
}

@media screen and (max-width: 820px){
    .mobile-icon{
        display: block;
        position: absolute;
        top: -20px;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
    }

    .nav-menu{
        display: none;
    }

    .navbar-item{
        display: none;
    }

    .nav-btn{
        display: none;
    }
    .navbar-container {
        padding: 0px 11px;
    }
    .navbar-logo {
        margin-left: 3vw;
    }
}

@media only screen and (max-width: 480px) {
    .navbar-logo {
        margin-left: 1vw;
    }
}
@media only screen and (max-width: 320px) {
    .navbar-logo {
        margin-left: 0vw;
    }
}

body {
	font-family: sans-serif;
	background-color: white;
}

.button {
	display: inline-block;
	margin: 4px 2px;
	background-color: #000;
	font-size: 14px;
	padding-left: 32px;
	padding-right: 32px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	color: white;
	text-decoration: none;
	cursor: pointer;
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.button:hover {
	transition-duration: 0.4s;
	-moz-transition-duration: 0.4s;
	-webkit-transition-duration: 0.4s;
	-o-transition-duration: 0.4s;
	background-color: black;
	color: white;
}

.search-container {
	position: relative;
	display: inline-block;
	margin: 4px 2px;
	height: 50px;
	width: 50px;
	vertical-align: bottom;
}

.mglass {
	display: inline-block;
	pointer-events: none;
    transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
}

.searchbutton {
	position: absolute;
	font-size: 22px;
	width: 100%;
	margin: 0;
	padding: 0;
}

.search:focus + .searchbutton {
	transition-duration: 0.4s;
	-moz-transition-duration: 0.4s;
	-webkit-transition-duration: 0.4s;
	-o-transition-duration: 0.4s;
	background-color: black;
	color: white;
}

.search {
	position: absolute;
	left: 49px; /* Button width-1px (Not 50px/100% because that will sometimes show a 1px line between the search box and button) */
	background-color: white;
	outline: none;
	border: none;
	padding: 0;
	width: 0;
	height: 100%;
	z-index: 10;
    color: black;
	transition-duration: 0.4s;
	-moz-transition-duration: 0.4s;
	-webkit-transition-duration: 0.4s;
	-o-transition-duration: 0.4s;
}

.search:focus {
	width: 280px; /* Bar width+1px */
	padding: 0 16px 0 0;
}

.expandright {
	left: auto;
	right: 49px; /* Button width-1px */
}

.expandright:focus {
	padding: 0 0 0 16px;
}
