#main {
  /*background-size: 100%; */
    min-height: 100vh;
    /* height: 312px; */
    /* background-repeat: no-repeat; */
    /* max-width: 100%; */
    width: 100%;
}
.aboutUsBannerImage {
  background-image: url('../../assets/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 46rem;
}
#one {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  margin-left: 220px;
  color: #231E28;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.gridrowgap{
  grid-row-gap: 1.5rem;
}

.grayscale{
  filter: grayscale(1)
}
.grayscale:hover{
  filter: brightness(1)
}
.valuetext {
  position: absolute;
  width: 90%;
  height: 85px;
  /* left: 134px; */
  top: 304.73px;
  /* Desktop/L – H3 */
  font-style: normal;
  font-weight: 600;
  font-size: 4vh;
  margin-left: 38px;
  margin-bottom: 2px;
  /* White */
  color: #FFFFFF;
}

.second {
  margin: 10px;
}

.two {
  width: 574px;
  height: 42px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
}

#three {
  font-family: 'Poppins';
  border-radius: 25px;
  border: 2px;
  gap: 10px;
  display: flex;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 224px;
  height: 46px;
  color: #FFFFFF;
}

#four {
  font-family: 'Poppins';
  border-radius: 25px;
  border: 2px;
  gap: 10px;
  display: flex;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  width: 146px;
  height: 46px;
}

#five {
  width: 447px;
  height: 256px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 33px;
  color: #231E28;
  flex: none;
  order: 0;
  flex-grow: 0;
}

#six {
  width: 464px;
  height: 160px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 33px;
  color: #231E28;
  flex: none;
  order: 0;
  flex-grow: 0;
}

#box {
  background-image: url('../../assets/Rectangle.png');
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: 30%;
  height: 65px;
}

.a {

  position: absolute;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;

  color: #868C92;
  cursor: pointer;
}


.b {

  /* position: absolute; */
  width: 204px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-left: 80px;
  text-align: left;
  color: #868C92;
  cursor: pointer;

}
.teamList {
  background-color: #F6F5F5;
  padding: 3rem 0rem;
}
.tactive {
  border-bottom: 3px solid #0492B1;
  width: 3rem;
  color: #0492B1;
  font-weight: bolder;
  padding-bottom: 8px;
  cursor: pointer;
}

.mactive {
  border-bottom: 3px solid #0492B1;
  /* width: 6rem; */
  color: #0492B1;
  font-weight: bolder;
  padding-bottom: 8px;
  cursor: pointer;
}
.teamnames{
  cursor: pointer;
}


.rounded-circle {

  width: 200px;
  height: 200px;
  /* margin-right: 280px; */
  margin-bottom: 30px;
  border-radius: 100px;

}

.name {
  width: 360px;
  height: 30px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  margin-left: 10px;
  text-align: center;
  color: #231E28;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.role {
  width: 360px;
  height: 26px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  text-align: center;
  margin-left: 10px;
  color: #76747A;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

/* .img {
  margin-left: 90px;
} */

.A {
  margin-left: 220px;

}

.B {
  margin-left: 220px;

}

#img {
  width: 668.5px;
  height: 445px;
  left: 0px;
  top: 0px;
  margin-left: 220px;
}

@media  screen and (max-width: 320px) {
  .contactFooterReverse{
    flex-wrap: wrap-reverse;
    text-align: center;
  }
  .footSocialLink {
    float: initial;
  }
  .aboutUsBannerImage {
    height: 24rem;
  }
  .valuetext {
    font-size: 2rem;
    top: 13rem;
    line-height: 3rem;
    left: -1rem;
  }

  #box {
    background-size: 86%;
    height: 55%;
    width: 100%;
  }

  #one {
    margin-left: 1rem;
    width: 90%;
    height: auto;
    font-size: 1rem;
    line-height: 1.6rem;
    padding: 0rem;
  }

  .A {
    margin-left: -0.5rem;
  }
  #three, #four {
    margin-bottom: 1.2rem;
    margin-top: 1.2rem;
    margin-left: 1rem;
    pointer-events: none;
  }


  #five,
  #six {
    width: 100%;
    height: auto;
    font-size: 1rem;
    line-height: 1.6rem;
    margin-left: 0.6rem;
  }

  


  .B {
    margin-left: 10px;
    margin-bottom: 60px;
  }
  .two {
    margin-left: 10px;
  }
  .two, .a, #three {
    width: 90%;
  }
  
  .teamList .teamimg {
    margin-left: 0px;
  }
  .teamList .name, .teamList .role{
    margin-left: 0px;
    width: 100%;
  }
  #main {
    min-height: 65vh;
  }
}
@media  screen and (min-width: 320px) {
  .contactFooterReverse{
    flex-wrap: wrap-reverse;
    text-align: center;
  }
  .footSocialLink {
    float: initial;
  }
  .aboutUsBannerImage {
    height: 24rem;
  }
  .valuetext {
    font-size: 2rem;
    top: 13rem;
    line-height: 3rem;
    left: -1rem;
  }

  #box {
    background-size: 86%;
    height: 55%;
    width: 100%;
  }

  #one {
    margin-left: 1rem;
    width: 90%;
    height: auto;
    font-size: 1rem;
    line-height: 1.6rem;
    padding: 0rem;
  }

  .A {
    margin-left: -0.5rem;
  }
  .mactive{
    margin-left: 0rem !important;
  }
  #three, #four {
    margin-bottom: 1.2rem;
    margin-top: 1.2rem;
    margin-left: 1rem;
    pointer-events: none;
  }


  #five,
  #six {
    width: 100%;
    height: auto;
    font-size: 1rem;
    line-height: 1.6rem;
    margin-left: 0.6rem;
  }

  


  .B {
    margin-left: 10px;
    margin-bottom: 60px;
  }
  .two {
    margin-left: 10px;
  }
  .two, .a, #three {
    width: 90%;
  }
  
  .teamList .teamimg {
    margin-left: 0px;
  }
  .teamList .name, .teamList .role{
    margin-left: 0px;
    width: 100%;
  }
  #main {
    min-height: 65vh;
  }
}
@media (min-width: 321px) and (max-width: 398px) {
  .contactFooterReverse{
    flex-wrap: wrap-reverse;
    text-align: center;
  }
  .footSocialLink {
    float: initial;
  }
  .aboutUsBannerImage {
    height: 26rem;
  }
  #main {
    min-height: 70vh;
  }
  .valuetext {
    font-size: 2rem;
    top: 13rem;
    line-height: 3rem;
    left: -1rem;
  }

  #box {
    background-size: 74%;
    height: 55%;
    width: 100%;
  }

  #one {
    margin-left: 1rem;
    width: 90%;
    height: auto;
    font-size: 1rem;
    line-height: 1.6rem;
    padding: 0rem;
  }

  .A {
    margin-left: -0.5rem;
  }
  .mactive{
    margin-left: -0.2rem !important;
  }
  #three, #four {
    margin-bottom: 1.2rem;
    margin-top: 1.2rem;
    margin-left: 0rem;
    pointer-events: none;
  }

  #five,
  #six {
    width: 100%;
    height: auto;
    font-size: 1rem;
    line-height: 1.6rem;
    margin-left: 0.6rem;
  }



  .B {
    margin-left: 10px;
    margin-bottom: 60px;
    width: 95%;
    float: right;
  }
  .two, .a, #three {
    width: 100%;
  }
  .teamList .teamimg {
    margin-left: 0px;
  }
  .teamList .name, .teamList .role{
    margin-left: 0px;
    width: 100%;
  }
}

@media (min-width: 399px) and (max-width: 480px) {
  .contactFooterReverse{
    flex-wrap: wrap-reverse;
    text-align: center;
  }
  .footSocialLink {
    float: initial;
  }
  .aboutUsBannerImage {
    height: 26rem;
  }
  #main {
    min-height: 70vh;
  }
  .valuetext {
    font-size: 3rem;
    top: 13rem;
    line-height: 3rem;
    left: -1rem;
  }

  #box {
    background-size: 96%;
    height: 64%;
    width: 100%;
  }

  #one {
    margin-left: 1rem;
    width: 90%;
    height: auto;
    font-size: 1rem;
    line-height: 1.6rem;
    padding: 0rem;
  }

  .A {
    margin-left: -0.5rem;
  }
  #three, #four {
    margin-bottom: 1.2rem;
    margin-top: 1.2rem;
    margin-left: 0rem;
    pointer-events: none;
  }

  #five,
  #six {
    width: 100%;
    height: auto;
    font-size: 1rem;
    line-height: 1.6rem;
    margin-left: 0.6rem;
  }



  .B {
    margin-left: 10px;
    margin-bottom: 60px;
    width: 85%;
    float: right;
  }
  .two, .a, #three {
    width: 100%;
  }
  .teamList .teamimg {
    margin-left: 0px;
  }
  .teamList .name, .teamList .role{
    margin-left: 0px;
    width: 100%;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .contactFooterReverse{
    flex-wrap: wrap-reverse;
    text-align: center;
  }
  .footSocialLink {
    float: initial;
  }
  .aboutUsBannerImage {
    height: 26rem;
  }
  #main {
    min-height: 70vh;
  }
  .valuetext {
    font-size: 3rem;
    top: 13rem;
    line-height: 3rem;
    left: 0rem;
  }

  #box {
    background-size: 54%;
    height: 64%;
    width: 100%;
  }

  #one {
    margin-left: 1rem;
    width: 90%;
    height: auto;
    font-size: 1rem;
    line-height: 1.6rem;
    padding: 0rem;
  }

  .A {
    margin-left: -0.5rem;
  }
  #three, #four {
    margin-bottom: 1.2rem;
    margin-top: 1.2rem;
    margin-left: 1rem;
    pointer-events: none;
  }

  #five,
  #six {
    width: 100%;
    height: auto;
    font-size: 1rem;
    line-height: 1.6rem;
    margin-left: 0.6rem;
  }


  .B {
    margin-left: 10px;
    margin-bottom: 60px;
    width: 50%;
    float: right;
  }
  .two {
    width: 100%;
  }
  .a {
    width: 47%;
  }
  #three {
    width: 30%;
  }
  .teamList .teamimg {
    margin-left: 0px;
  }
  .teamList .name, .teamList .role{
    margin-left: 0px;
    width: 100%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .contactFooterReverse{
    text-align: left;
  }
  .footSocialLink {
    float: right;
  }
  .aboutUsBannerImage {
    height: 34rem;
  }
  #main {
    min-height: 85vh;
  }
  .valuetext {
    font-size: 3.5rem;
    top: 13rem;
    line-height: 3.5rem;
    left: 0rem;
  }

  #box {
    background-size: 47%;
    height: 75%;
    width: 100%;
  }

  #one {
    margin-left: 1rem;
    width: 90%;
    height: auto;
    font-size: 1rem;
    line-height: 1.6rem;
    padding: 0rem;
  }
  .mactive{
    margin-left: -1.6rem !important;
  }


  .A {
    margin-left: -0.5rem;
  }
  #three, #four {
    margin-bottom: 1.2rem;
    margin-top: 1.2rem;
    margin-left: 1rem;
    pointer-events: none;
  }

  #five,
  #six {
    width: 100%;
    height: auto;
    font-size: 1rem;
    line-height: 1.6rem;
    margin-left: 0.6rem;
  }

  .B {
    margin-left: 0px;
    margin-bottom: 60px;
    width: 64%;
    float: right;
  }
  .two {
    width: 100%;
  }
  #three {
    width: 50%;
  }
  .a {
    width: 47%;
  }

  .teamList .teamimg {
    margin-left: 0px;
  }
  .teamList .name {
    font-size: 1.2rem;
  }
  .teamList .name, .teamList .role{
    margin-left: 0px;
    width: 100%;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .contactFooterReverse{
    text-align: left;
  }
  .footSocialLink {
    float: right;
  }
  .aboutUsBannerImage {
    height: 41rem;
  }
  #main {
    min-height: 65vh;
  }
  .valuetext {
    font-size: 4rem;
    top: 15rem;
    line-height: 4rem;
    left: 3rem;
  }

  #box {
    background-size: 53%;
    height: 86%;
    width: 87%;
  }

  #one {
    margin-left: 0px;
    width: 100%;
    height: auto;
    font-size: 18px;
    line-height: 30px;
  }

  .A {
    margin-left: 0px;
  }

  #five,
  #six {
    width: 100%;
    height: auto;
    font-size: 18px;
    line-height: 30px;
  }
  .B {
    margin-left: 0px;
    margin-bottom: 60px;
  }
  .two {
    width: 100%;
  }
  .a {
    width: 100%;
  }
   #three {
    width: 100%;
  }
  #three, #four {
    margin-bottom: 20px;
    margin-top: 20px;
    pointer-events: none;
  }
  .teamList .teamimg {
    margin-left: 0px;
  }
  .teamList .name {
    font-size: 1.2rem;
  }
  .teamList .name, .teamList .role{
    margin-left: 0px;
    width: 100%;
  }
}

@media (min-width: 1201px) and (max-width: 1450px) {
  .contactFooterReverse{
    text-align: left;
  }
  .footSocialLink {
    float: right;
  }
  .aboutUsBannerImage {
    height: 41rem;
  }
  #main {
    min-height: 65vh;
  }
  .valuetext {
    top: 17rem;
    left: 3.8rem;
    font-size: 5rem;
    line-height: 6rem;
  }
  #box {
    background-size: 58%;
    width: 82%;
    height: 116%;
  }

  #one {
    margin-left: 2rem;
    width: 90%;
    height: auto;
    font-size: 1.2rem;
    line-height: 1.9rem;
    padding: 0rem;
  }


  .A {
    margin-left: -1rem;
  }
  #three, #four {
    margin-bottom: 1.2rem;
    margin-top: 1.2rem;
    margin-left: 2rem;
    pointer-events: none;
  }
  #five, #six {
    width: 100%;
    height: auto;
    font-size: 1.2rem;
    line-height: 1.9rem;
    margin-left: 2rem;
  }
  .B {
    margin-left: 0px;
    margin-bottom: 60px;
    width: 47%;
    float: right;
  }
  .two {
    width: 100%;
  }
  .a {
    width: 48%;
  }
   #three {
    width: 40%;
  }
  .teamList .teamimg {
    margin-left: 0px;
  }
  .teamList .name, .teamList .role{
    margin-left: 0px;
    width: 100%;
  }
}

@media (min-width: 1451px) and (max-width: 2600px) {
  .contactFooterReverse{
    text-align: left;
  }
  .footSocialLink {
    float: right;
  }
  .aboutUsBannerImage {
    height: 46rem;
  }
  #main {
    min-height: 65vh;
  }

  .valuetext {
    top: 17rem;
    left: 3.8rem;
    font-size: 5rem;
    line-height: 6rem;
  }
  #box {
    background-size: 55%;
    width: 82%;
    height: 116%;
  }

  
  #one {
    margin-left: 0rem;
    width: 90%;
    height: auto;
    font-size: 1.2rem;
    line-height: 1.9rem;
    padding: 0rem;
  }


  .A {
    margin-left: -1rem;
  }
  #three, #four {
    margin-bottom: 1.2rem;
    margin-top: 1.2rem;
    pointer-events: none;
  }
  #five, #six {
    width: 100%;
    height: auto;
    font-size: 1.2rem;
    line-height: 1.9rem;
    margin-left: 0.3rem;
  }


  .B {
    margin-left: 0px;
    margin-bottom: 60px;
    float: right;
    width: 56%;
  }
  .two {
    width: 100%;
    margin-left: 0.2rem;
  }
  .a {
    width: 0%;
  }
   #three {
    width: 40%;
  }
  
  .teamList .teamimg {
    margin-left: 0px;
  }
  .teamList .name, .teamList .role{
    margin-left: 0px;
    width: 100%;
  }

  
}

.cssanimation, .cssanimation span {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.cssanimation span { display: inline-block }

.fadeInBottom { animation-name: fadeInBottom }
@keyframes fadeInBottom {
  from {
      opacity: 0;
      transform: translateY(10%);
  }
  to { opacity: 1 }
}