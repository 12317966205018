.hiringCardFixed {
    width: 13rem;
    position: fixed;
    bottom: 4rem;
    right: 1rem;
    z-index: 1;
}

.hiringCardFixed .card {
    background: #efefef;
    border-radius: 1rem;
    border-width: 0rem;
}

.hiringCardFixed .card-title {
    font-size: 1.3rem;
    padding-left: 0rem;
    margin-bottom: 0rem;
}

.hiringCardFixed .card-title:hover {
    color: #000;
}

.hiringCardFixed .card .card-body .heading {
    display: inline-flex;
    gap: 8px;
}

.hiringCardFixed .closeCard {
    background-color: transparent;
    border: none;
    float: right;
    text-align: right;
}

.hiringCardFixed .closeCard:hover {
    color: #fdc707;
}

.hiringCardFixed .cartPositionBtn {
    color: #FDC707;
    padding-left: 0rem;
    font-size: 0.9rem;
}
.hiringCardFixed .cartPositionBtn:hover {
    color: #FDC707;
}

@media screen and (max-width: 320px) {
    .hiringCardFixed{
        width: 12rem;
    }
    .hiringCardFixed .card .card-body .heading {
        gap: 13px;
    }
    .hiringCardFixed .card-title {
        font-size: 1.1rem;
    }
}
@media (min-width: 321px) and (max-width: 480px) {
    .hiringCardFixed{
        width: 12rem;
    }
    .hiringCardFixed .card .card-body .heading {
        gap: 13px;
    }
    .hiringCardFixed .card-title {
        font-size: 1.1rem;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .hiringCardFixed{
        width: 12rem;
    }
    .hiringCardFixed .card .card-body .heading {
        gap: 13px;
    }
    .hiringCardFixed .card-title {
        font-size: 1.1rem;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {

}
@media (min-width: 1025px) and (max-width: 1200px) {

}
@media (min-width: 1201px) and (max-width: 1450px) {

}
@media (min-width: 1451px) and (max-width: 2600px) {

}
/* .iconCircle {
    background: #e3e3e3;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    color: #6e6e6e;
    font-weight: bold;
    line-height: 40px;
    margin-right: 5px;
    text-align: center;
    width: 90px;
    height: 90px;
    position: fixed;
    bottom: 5rem;
    right: 2rem;
    background: linear-gradient(135deg, #a7cfdf 0%, #23538a 100%);
    box-shadow: inset -8px -7px 4px 0px rgba(0, 0, 0, 0.3);
    z-index: 1;
}

.faIcon {
    font-size: 3rem;
    bottom: 1rem;
    position: absolute;
    right: 1.5rem;
    color: coral;
}

.warp__placeholder {
    position: absolute;
    color: transparent;
    font-size: .1px;
}

.warp {
    display: block;
    position: relative;
    width: 131px;
    height: 118px;
    font: normal 16px/1 poppins;
    color: #000;
    font-weight: 900;
}

[class*='warp__'] {
    display: block;
    position: absolute;
    bottom: 8rem;
    right: 9.2rem;
}

.warp__0 {
    transform-origin: 84% 11px;
    transform: translate(15.611px, 57.906px) rotate(4.6539rad);
}

.warp__1 {
    transform-origin: 50% 16px;
    transform: translate(23.0648px, 43.2266px) rotate(-1.070435rad);
}

.warp__2 {
    transform-origin: 50% 16px;
    transform: translate(29.8714px, 33.83px) rotate(-0.958809rad);
}

.warp__3 {
    transform-origin: 50% 16px;
    transform: translate(35.839px, 24.7732px) rotate(-0.793366rad);
}

.warp__4 {
    transform-origin: 50% 16px;
    transform: translate(43.6705px, 16.6112px) rotate(-0.552046rad);
}

.warp__5 {
    transform-origin: 50% 16px;
    transform: translate(55.8908px, 12.5744px) rotate(-0.214541rad);
}

.warp__6 {
    transform-origin: 50% 16px;
    transform: translate(66.9064px, 13.6646px) rotate(0.236652rad);
}

.warp__7 {
    transform-origin: 50% 16px;
    transform: translate(77.7277px, 13.0562px) rotate(0.2543rad);
}

.warp__8 {
    transform-origin: 50% 16px;
    transform: translate(83.9895px, 16.383px) rotate(0.46rad);
}

.warp__9 {
    transform-origin: 50% 16px;
    transform: translate(93.4763px, 21.7725px) rotate(0.6353rad);
}

.warp__10 {
    transform-origin: 50% 16px;
    transform: translate(99.0096px, 29.7109px) rotate(0.8184rad);
}

.warp__11 {
    transform-origin: 50% 16px;
    transform: translate(108.821px, 39.5645px) rotate(1.1416rad);
}

.warp__12 {
    transform-origin: 50% 16px;
    transform: translate(113.7542px, 55.5983px) rotate(1.334327rad);
} */