.footerSection {
    background: #EEEEEE;
    font-family: 'Poppins';
    font-style: normal;
}

.footMenu li {
    margin-left: 0.8rem;
    font-weight: 700;
    line-height: 2rem;
}

.footServiceHeading {
    color: #000;
    font-size: 1rem;
    font-weight: 900;
    margin-left: 0.8rem;
}
.footServiceLink li {
    line-height: 30px;
    font-size: 15px;
    font-weight: 400;
    margin-left: 0.8rem;
}
.footerSection p {
    margin-left: 0.8rem;
}
.footServiceLink li a, .footMenu li a{
    color: #4E4B54;
    text-decoration: none;
}

.footerSection .card {
    background: #FFFFFF;
    box-shadow: 0px 4px 0px #fdc707;
    border-radius: 0rem;
    border-width: 0rem;
}
.footerSection .card .card-body {
    padding: 5% 11%;
}
.footerSection .card .card-body .footBodyContent {
    font-weight: 700;
    font-size: 20px;
}
.footerSection .card .card-body .positionBtn{
color: #FDC707;
    padding-left: 0rem;
}
.footLink {
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
}
.footLink li a {
    color: #A7A5A9;
    text-decoration: none;
}

.footSocialLink {
    float: right;
}
.footSocialLink li img {
    width: 60%;
}
.footSocialLink li .socialIcon {
    font-size: 1.5rem;
    filter: grayscale(1);
}
.footSocialLink li .socialIcon:hover {
    filter: brightness(1)
}
.footSocialLink li .fa {
    color: #1877f2;
}
.footSocialLink li .linkedin {
    color: #0a66c2;
}
.footSocialLink li .instagram {
    color: #c32aa3;
}

.footSocialLink li:not(:last-child), .footLink li:not(:last-child) {
    margin-right: 15px;
}

@media  screen and (min-width: 768px) {
    .contactFooterReverse {
        flex-wrap: wrap-reverse;
    }
    .footSocialLink {
        float: initial;
    }
    .footMenu li, .footServiceLink li  {
        margin-left: 0.8rem;
    }
}
@media (min-width: 1201px) and (max-width: 1450px){
    .footMenu li, .footServiceLink li  {
        margin-left: 0.8rem;
    }
}