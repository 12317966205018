.bg {
  background: #231E28;
  padding-bottom: 0rem;
}
.valueImageCarousel {
  background-color: #F6F5F5;
}
.hero {
  background-image: url('../../assets/valuehome.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 45rem;
}

.valuePage {
  background-color: #F6F5F5;
}
.valuePage .image1,
.valuePage .image2,
.valuePage .image3,
.valuePage .image4 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 300px;
}

.valuePage .image1 {
  background-image: url('../../assets/Perseverance.jpg');
}
.valuePage .image1::before {
  content: '';
  position: absolute;
  z-index: 2;
  background: linear-gradient(rgb(23 14 14 / 9%), rgb(53 53 53 / 80%));
}

.valuePage .image2 {
  background-image: url('../../assets/Process.jpg');
}

.valuePage .image3 {
  background-image: url('../../assets/MicrosoftTeams-image-people.png');
}

.valuePage .image4 {
  background-image: url('../../assets/MicrosoftTeams-image.png');
}

.imgcal_size {
  position: absolute;
}
.divwidth {
  position: absolute;
  width: 32rem;
  height: 25rem;
  top: 3rem;
  background: #0492B1;
}
.caption_text>h2 {
  font-size: 5.9vh;
  padding-left: 40px;
  padding-top: 40px;
  text-align: left;
  font-family: 'Poppins';
  font-weight: bolder;
  color: #FFFFFF;
}

.caption_text>P {
  font-size: 2vh;
  padding-left: 42px;
  padding-right: 20px;
  justify-content: left;
  text-align: left;
  font-family: 'Poppins';
  color: #FFFFFF;
}

.divtext {
  font-style: normal;
  font-weight: 600;
  font-size: 4vh;
  margin-left: -10px;
  margin-bottom: 2px;
  color: #000;
}

.valuePage .div2 {
  padding-top: 5%;
  padding-bottom: 2%;
}

.head-text {
  position: relative;
  color: white;

}

.center__text {
  position: absolute;
  top: 20%;
  left: 6.5%;
  transform: translate(-20%, -20%);
  font-style: normal;
  font-size: 2rem;
  color: white;
  /* text-shadow: 2px 2px black; */
}

h3 {
  font-weight: bolder;
  font-size: 4vw;
  font-style: normal;
}

.center__text1 {
  position: absolute;
  top: 20%;
  left: 55%;
  transform: translate(-20%, -20%);
  font-style: normal;
  font-size: 40px;
  color: white;
  /* text-shadow: 2px 2px black; */
}

.center__text2 {
  position: absolute;
  top: 70%;
  left: 4.5%;
  transform: translate(-20%, -20%);
  font-style: normal;
  font-size: 40px;
  color: white;
  /* text-shadow: 2px 2px black; */
}


.center__text3 {
  position: absolute;
  top: 70%;
  left: 55%;
  transform: translate(-20%, -20%);
  font-style: normal;
  font-size: 40px;
  color: white;
  /* text-shadow: 2px 2px black; */
}

@media screen and (max-width:786px) {
  .center__text {
    position: absolute;
    transform: translate(-20%, -20%);
    top: 25%;
    left: 10%;
    text-align: left;
    color: #FFFFFF;
    font-style: normal;

  }

  .center__text1 {
    position: absolute;
    transform: translate(-20%, -20%);
    top: 25%;
    left: 60%;
    text-align: left;
    color: #FFFFFF;
    font-style: normal;
  }

  .center__text2 {
    position: absolute;
    transform: translate(-20%, -20%);
    top: 70%;
    left: 6%;
    text-align: left;
    color: #FFFFFF;
    font-style: normal;
  }

  .center__text3 {
    position: absolute;
    transform: translate(-20%, -20%);
    top: 70%;
    left: 60%;
    text-align: left;
    color: #FFFFFF;
    font-style: normal;
  }

  .valuePage .image1,
  .valuePage .image2,
  .valuePage .image3,
  .valuePage .image4 {
    height: 240px;
  }

  .custom-tag1 .image1,
  .custom-tag1 .image2,
  .custom-tag1 .image3,
  .custom-tag1 .image4 {
    height: 400px;
  }
}



.custom-tag1 {
  max-width: 100%;
  height: 700px;
  background-attachment: fixed;
  background-position: top right;
}

.mask {
  width: 100;
  height: 100vh;
  position: relative;
}

.into-img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  object-fit: cover;
}

.mask::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.hero .content {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 23rem;
  left: 30rem;
  text-align: left;
  color: #FFFFFF;
}

.hero .content h1 {
  font-size: 5rem;
  font-weight: 900;
  line-height: 6rem;
}

.hero .content p {
  font-size: 1.5rem;
  line-height: 2rem;
}

.flip-card {
  background-color: transparent;
  /* width: 300px; */
  height: 400px;
  perspective: 1000px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.flip-card-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
  /* background: linear-gradient(rgb(23 14 14 / 9%), rgb(8 8 8 / 80%)); */
  /* background-color: rgba(0, 0, 0, 0.5);  */
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  /* background-color: #bbb; */
  color: transparent;
}

.flip-card-back {
  background-color: transparent;
  color: #000;
  transform: rotateY(180deg);
  text-align: left;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  display: grid;
  align-items: center;
  padding: 0.5rem 2rem;
}
.flip-card-back h1 {
  font-weight: 700;
}

@media screen and (min-width: 320px) {
  .bg{
    padding-bottom: 1rem;
  }
  .hero {
    background-position: 90% 100%;
    height: 30rem;
  }
  .hero .content {
    left: 7.5rem;
    top: 18rem;
  }
  .hero .content h1 {
    font-size: 2rem;
    line-height: 3rem;
  }
  .hero .content p {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .flip-card {
    height: 240px !important;
  }

  .flip-caption-text{
    font-size: 0.8rem;
  }
  .flip-card-back {
    padding: 0.5rem 0rem;
  }
  .valuePage .image1, .valuePage .image2, .valuePage .image3, .valuePage .image4 {
    height: 15rem;
  }
  .center__text, .center__text1, .center__text2, .center__text3 {
    font-size: 2rem;
  }
  .center__text {
    top: 6rem;
    left: 3.5rem;
  }
  .center__text1 {
    top: 6rem;
    left: 2.1rem;
  }
  .center__text2 {
    top: 6rem;
    left: 2rem;
  }
  .center__text3 {
    top: 6rem;
    left: 2rem; 
  }
  .valuePage .div2 {
    padding-top: 0%;
  }

  .valuePage .divtext{
    display: none;
  }
  .imgcalvalue {
    display: block;
    margin-top:1rem;
    margin-left: 0rem;
    font-size: 1.2rem;
    font-weight: 900;
  }

  .custom-tag1 {
    height: 28rem;
  }
  .imgcal_size {
    width: 16rem;
    height: 16rem;
    left: 0rem;
  }
  .divwidth {
    top: 6rem;
    height: 17rem;
    width: 90%;
    left: 3rem;
  }
  .caption_text>h2 {
    padding-left: 1rem;
    font-size: 1.7rem;
  }
  .caption_text>P {
    padding-left: 1rem;
    font-size: 0.7rem;
    width: 95%;
  }

  .carousel-indicators {
    margin-bottom: 1rem;
  }
}

@media (min-width: 321px) and (max-width: 480px) {
  .bg{
    padding-bottom: 1.5rem;
  }
  .hero {
    background-position: 90% 100%;
    height: 30rem;
  }
  .hero .content {
    left: 9.6rem;
    top: 17rem;
  }
  .hero .content h1 {
    font-size: 2rem;
    line-height: 3rem;
  }
  .hero .content p {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
  .flip-card {
    height: 260px !important;
  }
  .flip-caption-text{
    font-size: 0.8rem;
  }
  .flip-card-back {
    padding: 0.5rem 0rem;
  }
  .valuePage .image1, .valuePage .image2, .valuePage .image3, .valuePage .image4 {
    height: 16rem;
  }
  .center__text, .center__text1, .center__text2, .center__text3 {
    font-size: 2rem;
  }
  .center__text {
    top: 7rem;
    left: 3.1rem;
  }
  .center__text1 {
    top: 7rem;
    left: 2.1rem;
  }
  .center__text2 {
    top: 7rem;
    left: 2rem;
  }
  .center__text3 {
    top: 7rem;
    left: 2rem; 
  }
  .valuePage .div2 {
    padding-top: 0%;
  }

  .valuePage .divtext
   {
    display: none;
  }

  .imgcalvalue {
    display: block;
    margin-left: 0rem;
    font-size: 1.3rem;
    font-weight: 900;
  }

  .custom-tag1 {
    height: 27rem;
  }
  .imgcal_size {
    width: 18rem;
    height: 16rem;
    left: 0rem;
  }
  .divwidth {
    top: 8rem;
    height: 15rem;
    left: 4rem;
    width: 90%;
  }
  .caption_text>h2 {
    padding-left: 1rem;
    font-size: 1.7rem;
  }
  .caption_text>P {
    padding-left: 1rem;
    font-size: 0.7rem;
    width: 95%;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .bg{
    padding-bottom: 1.5rem;
  }
  .hero {
    background-position: 90% 100%;
    height: 35rem;
  }
  .hero .content {
    left: 17.6rem;
    top: 20rem;
  }
  .hero .content h1 {
    font-size: 3rem;
    line-height: 3rem;
  }
  .hero .content p {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
  .flip-card {
    height: 288px !important;
  }
  .flip-caption-text{
    font-size: 1rem;
    line-height: 1.3rem;
  }
  .flip-card-back {
    padding: 0.5rem 0.5rem;
  }

  .valuePage .image1, .valuePage .image2, .valuePage .image3, .valuePage .image4 {
    height: 18rem;
    width: 22.5rem;
  }
  .center__text, .center__text1, .center__text2, .center__text3 {
    font-size: 2rem;
  }
  .center__text {
    top: 7rem;
    left: 3.1rem;
  }
  .center__text1 {
    top: 7rem;
    left: 3rem;
  }
  .center__text2 {
    top: 7rem;
    left: 3rem;
  }
  .center__text3 {
    top: 7rem;
    left: 3rem;
  }
  .valuePage .div2 {
    padding-top: 0%;
  }

  .valuePage .divtext
 {
    display: none;
  }
  .imgcalvalue {
    display: block;
    margin-left: 1rem;
    font-size: 2rem;
    font-weight: 900;
  }

  .custom-tag1 {
    height: 34rem;
  }
  .imgcal_size {
    width: 25rem;
    height: 18rem;
    left: 1rem;
  }
  .divwidth {
    top: 4rem;
    height: 18rem;
    left: 17rem;
    width: 28rem;
  }
  .caption_text>h2 {
    padding-left: 1rem;
    font-size: 2.5rem;
  }
  .caption_text>P {
    padding-left: 1rem;
    font-size: 0.95rem;
    width: 95%;
  }
  .carousel-indicators {
    margin-left: 1rem;
  }
}

@media (min-width: 769px) and (max-width: 979px) {
  .bg{
    padding-bottom: 3rem;
  }
  .hero {
    background-position: 90% 100%;
    height: 40rem;
  }
  .hero .content {
    left: 22rem;
    top: 25rem;
  }
  .hero .content h1 {
    font-size: 4rem;
    line-height: 4rem;
  }
  .hero .content p {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
  .flip-card {
    height: 320px !important;
  }
  .flip-caption-text{
    font-size: 1rem;
  }
  .flip-card-back {
    padding: 0.5rem 0.5rem;
  }
  .valuePage .image1, .valuePage .image2, .valuePage .image3, .valuePage .image4 {
    height: 20rem;
    width: 22rem;
  }
  .center__text, .center__text1, .center__text2, .center__text3 {
    font-size: 2rem;
  }
  .center__text {
    top: 7rem;
    left: 3.3rem;
  }
  .center__text1 {
    top: 7rem;
    left: 3rem;
  }
  .center__text2 {
    top: 7rem;
    left: 3rem;
  }
  .center__text3 {
    top: 7rem;
    left: 3rem;
  }
  .valuePage .div2 {
    padding-top: 0%;
  }
  .valuePage .divtext
  {
    display: none;
  }

  .imgcalvalue {
    display: block;
    margin-left: 3rem;
    font-size: 2.3rem;
    font-weight: 900;
  }

  .custom-tag1 {
    height: 31rem;
  }
  .imgcal_size {
    width: 30rem;
    height: 21rem;
    left: 3rem;
  }
  .divwidth {
    top: 5rem;
    height: 21rem;
    left: 28rem;
    width: 28rem;
  }
  .caption_text>h2 {
    padding-left: 1rem;
    font-size: 2.5rem;
  }
  .caption_text>P {
    padding-left: 1rem;
    font-size: 0.95rem;
    width: 95%;
  }
  .carousel-indicators {
    margin-left: 2.5rem;
  }

}
@media (min-width: 980px) and (max-width: 1024px) {
  .bg{
    padding-bottom: 3rem;
  }
  .hero {
    background-position: 90% 100%;
    height: 40rem;
  }
  .hero .content {
    left: 22rem;
    top: 25rem;
  }
  .hero .content h1 {
    font-size: 4rem;
    line-height: 4rem;
  }
  .hero .content p {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
  .flip-card {
    height: 320px !important;
  }
  .flip-caption-text{
    font-size: 1.1rem;
  }
  .flip-card-back {
    padding: 0.5rem 0.5rem;
  }
  .valuePage .image1, .valuePage .image2, .valuePage .image3, .valuePage .image4 {
    height: 20rem;
    width: 30rem;
  }
  .center__text, .center__text1, .center__text2, .center__text3 {
    font-size: 2rem;
  }
  .center__text {
    top: 7rem;
    left: 3.3rem;
  }
  .center__text1 {
    top: 7rem;
    left: 3rem;
  }
  .center__text2 {
    top: 7rem;
    left: 3rem;
  }
  .center__text3 {
    top: 7rem;
    left: 3rem;
  }
  .valuePage .div2 {
    padding-top: 0%;
  }
  .valuePage .divtext
  {
    display: none;
  }

  .imgcalvalue {
    display: block;
    margin-left: 3rem;
    font-size: 2.3rem;
    font-weight: 900;
  }

  .custom-tag1 {
    height: 31rem;
  }
  .imgcal_size {
    width: 30rem;
    height: 21rem;
    left: 3rem;
  }
  .divwidth {
    top: 5rem;
    height: 21rem;
    left: 28rem;
    width: 28rem;
  }
  .caption_text>h2 {
    padding-left: 1rem;
    font-size: 2.5rem;
  }
  .caption_text>P {
    padding-left: 1rem;
    font-size: 0.95rem;
    width: 95%;
  }
  .carousel-indicators {
    margin-left: 2.5rem;
  }

}

@media (min-width: 1025px) and (max-width: 1200px) {
  .hero {
    background-position: 90% 100%;
    height: 40rem;
  }
  .hero .content {
    left: 23.5rem;
    top: 20rem;
  }
  .hero .content h1 {
    font-size: 4rem;
    line-height: 4rem;
  }
  .hero .content p {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
  .flip-card {
    height: 400px;
  }
  .flip-card-back {
    padding: 0.5rem 0.5rem;
  }
}

@media (min-width: 1201px) and (max-width: 1366px) {
  .hero {
    background-position: 90% 100%;
    height: 40rem;
  }
  .hero .content {
    left: 24.5rem;
    top: 25rem;
  }
  .hero .content h1 {
    font-size: 4rem;
    line-height: 4rem;
  }
  .hero .content p {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
  .flip-caption-text{
    font-size: 1.2rem;
  }
  .flip-card-back {
    padding: 0.5rem 1rem;
  }

  .valuePage {
    padding: 2rem 5rem 3rem 5rem;
  }
  .valuePage .image1, .valuePage .image2, .valuePage .image3, .valuePage .image4 {
    height: 25rem;
    width: 34rem;
  }
  .center__text, .center__text1, .center__text2, .center__text3 {
    font-size: 2.3rem;
  }
  .center__text {
    top: 12rem;
    left: 4rem;
  }
  .center__text1 {
    top: 12rem;
    left: 3rem;
  }
  .center__text2 {
    top: 12rem;
    left: 3rem;
  }
  .center__text3 {
    top: 12rem;
    left: 3rem;
  }
  .valuePage .div2 {
    padding-top: 0%;
  }
  .valuePage .divtext {
    display: block;
    font-size: 2.5rem;
  }

  .imgcalvalue {
    display: block;
    margin-left: 3rem;
    font-size: 2.5rem;
    font-weight: 900;
  }
  .valueImageCarousel {
    padding: 2rem 2rem 3rem 2.5rem;
  }
  .custom-tag1 {
    height: 40rem;
  }
  .imgcal_size {
    width: 40rem;
    height: 25rem;
    left: 3rem;
    top: 2rem;
  }
  .divwidth {
    top: 9rem;
    height: 25rem;
    left: 35rem;
    width: 32rem;
  }
  .caption_text>h2 {
    padding-left: 1rem;
    font-size: 3rem;
  }
  .caption_text>P {
    padding-left: 1rem;
    font-size: 1.1rem;
    width: 95%;
  }
  .carousel-indicators {
    margin-left: 2.5rem;
  }
  .flip-card {
    height: 400px !important;
  }
}

@media (min-width: 1367px) and (max-width: 1450px) {
  .hero {
    background-position: 90% 100%;
    height: 40rem;
  }
  .hero .content {
    left: 24.5rem;
    top: 25rem;
  }
  .hero .content h1 {
    font-size: 4rem;
    line-height: 4rem;
  }
  .hero .content p {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
  .flip-caption-text{
    font-size: 1.2rem;
  }
  .flip-card-back {
    padding: 0.5rem 1rem;
  }

  .valuePage {
    padding: 2rem 5rem 3rem 5rem;
  }
  .valuePage .image1, .valuePage .image2, .valuePage .image3, .valuePage .image4 {
    height: 25rem;
    width: 40rem;
  }
  .center__text, .center__text1, .center__text2, .center__text3 {
    font-size: 2.3rem;
  }
  .center__text {
    top: 12rem;
    left: 4rem;
  }
  .center__text1 {
    top: 12rem;
    left: 3rem;
  }
  .center__text2 {
    top: 12rem;
    left: 3rem;
  }
  .center__text3 {
    top: 12rem;
    left: 3rem;
  }
  .valuePage .div2 {
    padding-top: 0%;
  }
  .valuePage .divtext {
    display: block;
    font-size: 2.5rem;
  }

  .imgcalvalue {
    display: block;
    margin-left: 3rem;
    font-size: 2.5rem;
    font-weight: 900;
  }
  .valueImageCarousel {
    padding: 2rem 2rem 3rem 2.5rem;
  }
  .custom-tag1 {
    height: 40rem;
  }
  .imgcal_size {
    width: 40rem;
    height: 25rem;
    left: 3rem;
    top: 2rem;
  }
  .divwidth {
    top: 9rem;
    height: 25rem;
    left: 35rem;
    width: 32rem;
  }
  .caption_text>h2 {
    padding-left: 1rem;
    font-size: 3rem;
  }
  .caption_text>P {
    padding-left: 1rem;
    font-size: 1.1rem;
    width: 95%;
  }
  .carousel-indicators {
    margin-left: 2.5rem;
  }
  .flip-card {
    height: 400px !important;
  }
}

@media (min-width: 1451px) and (max-width: 2600px) {
  .hero {
    height: 46rem;
  }
  .hero .content {
    left: 26.8rem;
    top: 25rem;
  }
  .hero .content h1 {
    font-size: 5rem;
    line-height: 6rem;
  }
  .hero .content p {
    font-size: 1.3rem;
    line-height: 1.8rem;
  }
  .flip-card {
    height: 400px !important;
  }

  .flip-caption-text{
    font-size: 1.3rem;
  }
  .flip-card-back {
    padding: 0.5rem 2rem;
  }
  .valuePage {
    padding: 2rem 5rem 3rem 5rem;
  }
  .valuePage .image1, .valuePage .image2, .valuePage .image3, .valuePage .image4 {
    height: 25rem;
    width: 41.2rem;
  }
  .center__text, .center__text1, .center__text2, .center__text3 {
    font-size: 2.5rem;
  }
  .center__text {
    top: 12rem;
    left: 4.4rem;
  }
  .center__text1 {
    top: 12rem;
    left: 3rem;
  }
  .center__text2 {
    top: 12rem;
    left: 3rem;
  }
  .center__text3 {
    top: 12rem;
    left: 3rem;
  }
  .valuePage .div2 {
    padding-top: 0%;
  }
  .valuePage .divtext {
    display: block;
    font-size: 2.5rem;
  }
  

  .imgcalvalue {
    display: block;
    margin-left: 3rem;
    margin-bottom: 0rem;
    font-size: 2.5rem;
    font-weight: 900;
  }
  .valueImageCarousel {
    padding: 2rem 2rem 3rem 3.2rem;
  }
  .custom-tag1 {
    height: 40rem;
  }
  .imgcal_size {
    width: 40rem;
    height: 25rem;
    left: 3rem;
    top: 2rem;
  }
  .divwidth {
    top: 9rem;
    height: 25rem;
    left: 35rem;
    width: 32rem;
  }
  .caption_text>h2 {
    padding-left: 2rem;
    font-size: 3rem;
  }
  .caption_text>P {
    padding-left: 2rem;
    font-size: 1.1rem;
    width: 95%;
  }
  .carousel-indicators {
    margin-left: 2.5rem;
  }

}
