.sidebar-container {
    position: fixed;
    z-index:999;
    width: 100%;
    height: 100%;
    background: #000;
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;

}

.icon-div{
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
}

.sidebar-wrapper{
    color: #fff;
}

.sidebar-menu{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(8, 0px) ;
    text-align: center;
    padding-left: 0rem;
}

.sidebar-link{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem ;
    text-decoration: none;
    list-style:none;
    transition: 0.2s ease-in-out;
    color: #fff ;
    cursor: pointer;

}

.side-btn-wrap{
    display: flex;
    justify-content: center;
}

.side-router{
    border-radius: 50px;
    background: #01bf71;
    white-space: nowrap;
    padding: 16px 64px;
    color: #fff;
    font-size: 16px;
    outline:none;
    border: none ;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

}

.open{
    opacity: 100%;
    top:0;
}

.close{
    opacity: 0;
    top: -100%;
}

@media screen and (max-width: 480px){
    .sidebar-menu{
        grid-template-rows: repeat(8, 60px) ;
    }
}

@media screen and (max-width: 769px){
    .sidebar-menu{
        grid-template-rows: repeat(8, 60px) ;
    }
}