body {
    background-color: #f6f5f5;
}

.blog-HeadElement {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.blog-imgcls {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
}

.blog-image-container {
    background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem 2rem;
    height: 46rem;
    position: relative;
    z-index: 1;
}

.blog-image-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    background: linear-gradient(rgb(23 14 14 / 9%), rgb(53 53 53 / 80%));
}

.blog-HeadText {
    font-family: 'Poppins';
    font-size: 6vw;
    font-weight: bolder;
    color: #FFFFFF;
}

.blog-head-content {
    z-index: 3;
    max-width: 1500px;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
}
.blogPageSection .card img {
    width: 100%;
    height: auto;
}
.blogPageSection .card-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 23px;
    line-height: 26px;
    text-align: left;
    color: #231E28;
    padding: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: 2.8em;
    font-size: 1.4em;
}

.blogPageSection .card-title:hover {
    color: #fff;
}

.blogPageSection .card-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 26px;
    color: #868C92;
    text-align: left;
    padding: 11px;
    border-bottom: 2px solid #007afe;
}
.blogPageSection .card-text:hover {
    color: #868C92;
}

.blogPageSection .card-body .card-title .card-link{
    color: #000;
    text-decoration: none;
}

.ReadMore{
    border-radius: 0rem;
    background-color: transparent;
    border-color: transparent;
    text-align: left;
    padding-left: 0.7rem;
}
.ReadMore:hover {
    background-color: transparent;
    border: 0px solid transparent;
}
.ReadMore:focus {
    background-color: transparent;
}

.blogPageSection h5 {
    border-bottom: 2px solid #007afe;
    line-height: 2rem;
    width: 8rem;
}

.card-img-top {
    height: 237px !important;
}

@media screen and (min-width: 320px) {
    .blog-image-container {
        height: 450px;
    }

    .blog-head-content {
        /* margin-left: 0px; */
        left: -0.5rem;
    }

    .blog-HeadText {
        font-size: 8vw;
        margin-top: 150px;
    }
    .blogPageSection h5 {
        margin-top: 2rem;
    }
    .blogCategory {
        margin-left: 0.5rem;
    }
}

@media (min-width: 321px) and (max-width: 480px) {
    /* .blog-image-container {} */

    .blog-head-content {
        /* margin-left: -10px; */
        left: -0.3rem;
    }

    .blog-HeadText {
        font-size: 8vw;
        margin-top: 150px;
    }
    .blogPageSection h5 {
        margin-top: 2rem;
    }
    .blogCategory {
        margin-left: 0.5rem;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    /* .blog-image-container {} */

    .blog-head-content {
        /* margin-left: -240px; */
        left: 1rem;
    }

    .blog-HeadText {
        font-size: 5vw;
        margin-top: 150px;
        width: 19rem;
    }
    .blogPageSection h5 {
        margin-top: 1rem;
    }
    .blogCategory {
        margin-left: 0.5rem;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .blog-image-container {
        height: 500px;
    }

    .blog-head-content {
        /* margin-left: -330px; */
        left: 1.5rem;
    }

    .blog-HeadText {
        font-size: 5vw;
        margin-top: 150px;
        width: 27rem;
    }
    .blogPageSection h5 {
        margin-top: 1rem;
    }
    .blogCategory {
        margin-left: 0rem;
    }
}

@media (min-width: 1025px) and (max-width: 1200px) {
    .blog-image-container {
        height: 500px;
    }

    .blog-head-content {
        /* margin-left: -165px; */
        left: 1.4rem;
    }

    .blog-HeadText {
        font-size: 5vw;
        margin-top: 150px;
    }
    .blogPageSection h5 {
        margin-top: 0rem;
    }
    .blogCategory {
        margin-left: 0rem;
    }
}

@media (min-width: 1201px) and (max-width: 1450px) {
    .blog-image-container {
        height: 40rem;
    }

    .blog-head-content {
        /* margin-left: -21rem; */
        left: 3.7rem;
    }

    .blog-HeadText {
        font-size: 3.3rem;
        margin-top: 5rem;
        width: 27rem;
    }
    .blogPageSection h5 {
        margin-top: 0rem;
    }
    .blogCategory {
        margin-left: 0rem;
    }
}

@media (min-width: 1451px) and (max-width: 2600px) {
    .blog-image-container {
        height: 46rem;
    }

    .blog-head-content {
        /* margin-left: -24rem; */
        left: 4.5rem;
    }

    .blog-HeadText {
        font-size: 3.4rem;
        margin-top: 5rem;
        width: 27rem;
    }
    .blogPageSection h5 {
        margin-top: 0rem;
    }
    .blogCategory {
        margin-left: 0rem;
    }
}