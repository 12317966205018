.contactushero {
  background-image: url('../../assets/valuehome.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 45rem;
}

.contactushero .content {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 23rem;
  left: 30rem;
  text-align: left;
  color: #FFFFFF;
}

.contactushero .content h1 {
  font-size: 5rem;
  font-weight: 900;
  line-height: 6rem;
}

.contactushero .content p {
  font-size: 1.5rem;
  line-height: 2rem;
}

.contactPageForm button.customBtn {
  width: 50%;
  background-color: transparent;
  border: 1px solid #007afe;
  color: #007afe;
}
/* .contactPageForm button.customBtn:hover {
  background-color: #007afe;
  color: #fff;
} */
@media screen and (min-width: 320px) {
  .contactushero {
    background-position: 90% 100%;
    height: 30rem;
  }
  .contactushero .content {
    left: 7.5rem;
    top: 18rem;
  }
  .contactushero .content h1 {
    font-size: 3rem;
    line-height: 3rem;
  }
  .contactushero .content p {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 321px) and (max-width: 480px) {
  .contactushero {
    background-position: 90% 100%;
    height: 30rem;
  }
  .contactushero .content {
    left: 9.6rem;
    top: 17rem;
  }
  .contactushero .content h1 {
    font-size: 3rem;
    line-height: 3rem;
  }
  .contactushero .content p {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .contactushero {
    background-position: 90% 100%;
    height: 35rem;
  }
  .contactushero .content {
    left: 17.6rem;
    top: 20rem;
  }
  .contactushero .content h1 {
    font-size: 3rem;
    line-height: 3rem;
  }
  .contactushero .content p {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .contactushero {
    background-position: 90% 100%;
    height: 40rem;
  }
  .contactushero .content {
    left: 22rem;
    top: 25rem;
  }
  .contactushero .content h1 {
    font-size: 4rem;
    line-height: 4rem;
  }
  .contactushero .content p {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .contactushero {
    background-position: 90% 100%;
    height: 40rem;
  }
}

@media (min-width: 1201px) and (max-width: 1450px) {
  .contactushero {
    background-position: 90% 100%;
    height: 40rem;
  }
  .contactushero .content {
    left: 24.5rem;
    top: 25rem;
  }
  .contactushero .content h1 {
    font-size: 4rem;
    line-height: 4rem;
  }
  .contactushero .content p {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

@media (min-width: 1451px) and (max-width: 2600px) {
  .contactushero {
    height: 46rem;
  }
  .contactushero .content {
    left: 26.8rem;
    top: 25rem;
  }
  .contactushero .content h1 {
    font-size: 5rem;
    line-height: 6rem;
  }
  .contactushero .content p {
    font-size: 1.3rem;
    line-height: 1.8rem;
  }
}
