.banner-container {
  position: relative;
  background: #fff;
  padding-bottom: 0rem;

  background-image: url('../../assets/pic3.png');
  background-repeat: no-repeat;
  background-size: 100%;
  height: 40rem;
  background-position: 10% 3%;
}
.banner-container img {
  width: cover;
}

.text {
  position: absolute;
  top: 20rem;
  left: 3rem;
}

.main-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900 !important;
  margin-left: 3.1rem;
  font-size: 5rem;
  line-height: 6rem;
  color: #EEEEEE;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.first {
  position: absolute;
  order: 0;
  top: 37rem;
  left: 0;
  width: 87.4%;
  height: 8rem;
  background-color: #0492B1;
}

.first-text {
  position: absolute;
  left: 6.2rem;
  top: 1.3rem;
  bottom: 1rem;
  width: 90%;
  height: 0rem;
}

.first-text-size {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.8rem;
  /* or 182% */
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
}

@media only screen and (max-width: 1200px) {
  .text {
    top: 14rem;
    left: 1.5rem;
  }
  .main-text {
    font-size: 4rem;
    line-height: 4.3rem;
  }
  .body-div {
    padding: 8rem 0rem 1rem 4rem;
  }
  .first {
    position: absolute;
    order: 0;
    top: 28rem;
    left: 0;
    width: 85%;
    height: 9.5rem;
    background-color: #0492B1;
  }

  .first-text-size {
    font-size: 18px;
  }

  .first-text {
    position: absolute;
    left: 5rem;
    top: 1rem;
    bottom: 1rem;
    width: 90%;
    height: 0rem;
  }

  .banner-container {
    height: 32rem;
  }
  .cards-container {
    padding: 1rem 3rem;
  }
}

@media only screen and (max-width: 1024px) {
  .text {
    top: 11rem;
    left: 3rem;
  }
  .main-text {
    font-size: 3.5rem;
    margin-top: 2rem;
    margin-left: 0rem;
    line-height: 4.5rem;
  }
  .body-div {
    padding: 7rem 0rem 1rem 1rem;
  }
  .first {
    position: absolute;
    order: 0;
    top: 28rem;
    left: 0;
    width: 83%;
    height: 9rem;
    background-color: #0492B1;
  }

  .first-text {
    position: absolute;
    left: 3rem;
    top: 1rem;
    bottom: 1rem;
    width: 90%;
    height: 0rem;
  }

  .first-text-size {
    font-size: 1rem;
    line-height: 1.7rem;
  }

  .banner-container {
    background-size: cover;
    height: 33rem;
  }
  .cards-container .card h2 {
    font-size: 1rem;
  }
  .cards-container .card p {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 950px) {
  .text {
    top: 14rem;
    left: 3rem;
  }
  .main-text {
    font-size: 3.5rem;
    margin-top: 2rem;
    margin-left: -0.8rem;
    line-height: 4rem;
  }
  .body-div {
    padding: 9rem 0rem 1rem 0rem;
  }
  .first {
    position: absolute;
    order: 0;
    top: 26rem;
    left: 0;
    width: 95%;
    height: 10.5rem;
    background-color: #0492B1;
  }

  .first-text {
    position: absolute;
    left: 2rem;
    top: 1.2rem;
    bottom: 1rem;
    width: 90%;
    height: 0rem;
  }

  .first-text-size {
    font-size: 1rem;
    line-height: 1.6rem;
  }

  .banner-container {
    background-size: cover;
    height: 30rem;
    background-position-x: right;
  }
  .cards-container .card h2 {
    font-size: 1rem;
  }
  .cards-container .card p {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 480px) {
  .banner-container {
    /* background-size: cover;
    height: 25rem; */
    background-size: contain;
    background-position-x: center;
    height: 23rem;
    background-position-y: center;
  }

  .banner-container img {
    height: 25rem;
  }
  .body-div {
    padding: 12rem 0rem 1rem 0rem;
  }
  .main-text {
    font-size: 2.5rem;
    margin-left: -1rem;
    line-height: 2.5rem;
  }

  .text {
    position: absolute;
    top: 9rem;
    left: 2rem;
  }

  .first {
    position: absolute;
    order: 0;
    top: 17rem;
    left: 0;
    width: 90%;
    height: 15rem;
    background-color: #0492B1;
  }

  .first-text {
    position: absolute;
    left: 2rem;
    top: 1rem;
    bottom: 1rem;
    width: 90%;
    height: 0rem;
  }

  .first-text-size {
    font-size: 1rem;
    line-height: 1.3rem;
    margin-left: -1rem;
  }
}

@media only screen and (max-width: 320px) {
  .banner-container {
    background-size: contain;
    height: 21rem;
  }
  .body-div {
    padding: 12rem 0rem 0.5rem 0rem;
  }
  .main-text {
    margin-left: -1rem;
    line-height: 2rem;
    font-size: 2rem;
  }
  .text {
    position: absolute;
    top: 8rem;
    left: 2rem;
  }

  .first {
    width: 90%;
    height: 16.5rem;
    top: 15rem;
  }

  .first-text {
    width: 90%;
    left: 1rem;
  }
  .first-text-size { 
    margin-left: 0rem;
    font-size: 0.9rem;
  }
  .cards-container .card h2 {
    font-size: 1rem;
  }
  .cards-container .card p {
    font-size: 0.8rem;
  }
}