
@import url('https://fonts.googleapis.com/css?family=Poppins');

body {

    margin: 0;

    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",

        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",

        sans-serif;

    -webkit-font-smoothing: antialiased;

    -moz-osx-font-smoothing: grayscale;

}
* {
    font-family: 'Poppins' !important;
}

code {

    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",

        monospace;

}
