.col-box{
    width: 447px;
    margin-left: 129px;
    margin-bottom: 30px;
}

.col-btn{
    pointer-events: none;
  
    width: 295px;
    height: 46px;
    border-color: unset;
    font-weight: 800;
    background: #0492B1;
    border-radius: 100px;
}

@media only screen and (max-width: 480px) {
    .col-box{
        width: 335px;
        margin-left: 30px;
    }
}