body {
    background-color: #f6f5f5;
}

.blogdetails-HeadElement {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.blogdetails-imgcls {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
}

.blogdetails-image-container {
    background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem 2rem;
    height: 46rem;
    position: relative;
    z-index: 1;
}

.blogdetails-image-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    background: linear-gradient(rgb(23 14 14 / 9%), rgb(53 53 53 / 80%));
}

.blogdetails-HeadText {
    font-family: 'Poppins';
    font-size: 6vw;
    font-weight: bolder;
    color: #FFFFFF;
    /* width: 27rem; */
}

.blogdetails-head-content {
    z-index: 3;
    max-width: 1500px;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
}

.blogdetailsPageSection h5 {
    font-weight: 600;
    font-size: 1.2rem;
    margin-top: 1.7rem;
}
.blogdetailsPageSection p {
    margin-bottom: 0.5rem;
}
.blogdetailsPageSection h6 {
    border-bottom: 2px solid #0492B1;
    line-height: 2rem;
    width: 8rem;
}
.blogdetailsPageSection .blog-image {
    width: 75%;
}

@media screen and (min-width: 320px) {
    .blogdetails-image-container {
        height: 450px;
    }

    .blogdetails-head-content {
        /* margin-left: 0px; */
        left: -0.5rem;
    }

    .blogdetails-HeadText {
        font-size: 8vw;
        margin-top: 150px;
        /* width: 14rem; */
    }
    .blogdetailsPageSection .blog-image {
        width: 100%;
    }
    .blogdetailsPageSection h6 {
        margin-top: 2rem;
    }
}

@media (min-width: 321px) and (max-width: 480px) {
    /* .blogdetails-image-container {} */

    .blogdetails-head-content {
        /* margin-left: -10px; */
        left: -0.3rem;
    }

    .blogdetails-HeadText {
        font-size: 8vw;
        margin-top: 150px;
        /* width: 27rem; */
    }
    .blogdetailsPageSection .blog-image {
        width: 100%;
    }
    .blogdetailsPageSection h6 {
        margin-top: 2rem;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    /* .blogdetails-image-container {} */

    .blogdetails-head-content {
        /* margin-left: -240px; */
        left: 1rem;
    }

    .blogdetails-HeadText {
        font-size: 5vw;
        margin-top: 150px;
        width: 19rem;
    }
    .blogdetailsPageSection .blog-image {
        width: 90%;
    }
    .blogdetailsPageSection h6 {
        margin-top: 1rem;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .blogdetails-image-container {
        height: 500px;
    }

    .blogdetails-head-content {
        /* margin-left: -330px; */
        left: 1.5rem;
    }

    .blogdetails-HeadText {
        font-size: 5vw;
        margin-top: 150px;
        width: 27rem;
    }
    .blogdetailsPageSection .blog-image {
        width: 90%;
    }
    .blogdetailsPageSection h6 {
        margin-top: 1rem;
    }
}

@media (min-width: 1025px) and (max-width: 1200px) {
    .blogdetails-image-container {
        height: 500px;
    }

    .blogdetails-head-content {
        /* margin-left: -165px; */
        left: 1.4rem;
    }

    .blogdetails-HeadText {
        font-size: 5vw;
        margin-top: 150px;
    }
    .blogdetailsPageSection h6 {
        margin-top: 0rem;
    }
}

@media (min-width: 1201px) and (max-width: 1450px) {
    .blogdetails-image-container {
        height: 40rem;
    }

    .blogdetails-head-content {
        /* margin-left: -21rem; */
        left: 3.7rem;
    }

    .blogdetails-HeadText {
        font-size: 3.3rem;
        margin-top: 5rem;
        width: 27rem;
    }
    .blogdetailsPageSection .blog-image {
        width: 75%;
    }
    .blogdetailsPageSection h6 {
        margin-top: 0rem;
    }
}

@media (min-width: 1451px) and (max-width: 2600px) {
    .blogdetails-image-container {
        height: 46rem;
    }

    .blogdetails-head-content {
        /* margin-left: -24rem; */
        left: 4.5rem;
    }

    .blogdetails-HeadText {
        font-size: 3.4rem;
        margin-top: 5rem;
        width: 27rem;
    }
    .blogdetailsPageSection .blog-image {
        width: 75%;
    }
    .blogdetailsPageSection h6 {
        margin-top: 0rem;
    }
}
