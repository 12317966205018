/* Banner Content and Image */
.serviceBannerImage {
    background-image: url('../../assets/serviceBannerImg.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 73rem;
}
.serviceBannerContent {
    font-family: 'Poppins';
    position: absolute;
    transform: translate(-50%, -50%);
    font-style: normal;
    font-weight: 900;
    font-size: 6rem;
    line-height: 1rem;
    align-items: center;
    color: #FFFFFF;
}
.serviceBannerContent h1{
    font-size: 3rem;
    font-weight: 700;
}
.serviceBannerContent p{
    font-size: 1.1rem;
    line-height: 2rem;
    font-weight: 300;
}

/* First Section */
.serviceFirstSection .bgColor {
    background-color: #0492B1;
    cursor: pointer;
}

.serviceFirstSection .serviceIcons {
    width: 4rem;
}
.serviceFirstSection .bgImg1, .serviceFirstSection .bgImg2, .serviceFirstSection .bgImg3, .serviceFirstSection .bgImg4, .serviceFirstSection .bgImg5 {
    background-repeat: no-repeat;
    background-size: cover;
}
.serviceFirstSection .bgImg1 {
    background-image: url('../../assets/embedded.png');
}
.serviceFirstSection .bgImg2 {
    background-image: url('../../assets/iot.png');
}
.serviceFirstSection .bgImg3 {
    background-image: url('../../assets/electricVehicle.png');
}
.serviceFirstSection .bgImg4 {
    background-image: url('../../assets/cloudComputing.png');
}
.serviceFirstSection .bgImg5 {
    background-image: url('../../assets/industry4-0.png');
}

.serviceFirstSection .serviceSectionContent {
    padding: 12rem 5rem;
}

.serviceFirstSection .serviceSectionContent h3 {
    font-size: 2rem;
    font-weight: 900;
    color: #fff;
    cursor: pointer;
    margin-bottom: 1rem;
}
/* .serviceFirstSection .serviceSectionContent h3:hover {
    color: #868c92;
} */
.serviceFirstSection .serviceSectionContent p{
    font-size: 1.5rem;
    line-height: 2rem;
    color: #fff;
    font-weight: 100;
}

/* Second Section Content and Images */
.secondSectionContent {
    width: 0.8rem;
    margin:auto;
}
.serviceSecondSection .secondSectionContent h4{
    font-size: 2rem;
    font-weight: 700;
}
.serviceSecondSection .secondSectionContent p{
    font-size: 1.2rem;
}

.serviceThirdSection, .serviceFourthSection, .serviceFifthSection, .serviceSixSection, .serviceSevenSection {
    background-repeat: no-repeat;
    background-size: cover;
    height: 14.375rem;
    background-position: center;
}
.serviceFourthSection {
    background-image: url('../../assets/embedded-banner.png');
}
.serviceThirdSection {
    background-image: url('../../assets/iot-banner.png');
}
.serviceFifthSection {
    background-image: url('../../assets/electric-vehicle-banner.png');
}
.serviceSixSection {
    background-image: url('../../assets/cloud-computing-banner.png');
}
.serviceSevenSection {
    background-image: url('../../assets/industry4-0-banner.png');
}



@media  screen and (min-width: 320px) {
    .serviceBannerImage {
        height: 26rem;
        background-position: 80% 100%;
    }
    .serviceBannerContent {
        top: 14rem;
        font-size: 1.4rem;
        left: 9rem;
    }
    .serviceBannerContent h1{
        font-size: 1.7rem;
    }
    .serviceBannerContent p{
        font-size: 0.9rem;
        width: 16rem;
        word-wrap: break-word;
    }
    .serviceFirstSection .bgColor {
        margin:0.6rem 0rem 0.3rem 0rem;
    }
    .serviceFirstSection .serviceIcons {
        width: 3rem;
    }
    .serviceFirstSection .serviceSectionContent {
        padding: 1rem 1rem;
    }
    .serviceFirstSection .serviceSectionContent p {
        font-size: 1rem;
    }
    .serviceFirstSection .serviceSectionContent h3 {
        font-size: 1.2rem;
    }

    .secondSectionContent {
        width: 18rem;
    }
    .serviceSecondSection .secondSectionContent h4{
        font-size: 1.2rem;
    }
    .serviceSecondSection .secondSectionContent p{
        font-size: 1rem;
    }
}

@media (min-width: 321px) and (max-width: 480px) {
    .serviceBannerImage {
        height: 26rem;
        background-position: 80% 100%;
    }
    .serviceBannerContent {
        top: 14rem;
        font-size: 1.5rem;
        left: 11rem;
    }
    .serviceBannerContent h1{
        top: 14rem;
        font-size: 2rem;
        left: 8.3rem;
    }
    .serviceBannerContent p{
        font-size: 0.9rem;
        width: 19rem;
        word-wrap: break-word;
    }
    .serviceFirstSection .serviceIcons {
        width: 3rem;
    }
    .serviceFirstSection .bgColor {
        margin: 0.6rem 0rem 0.3rem 0rem;
    }
    .serviceFirstSection .serviceSectionContent {
        padding:1rem 1rem;
    }
    .serviceFirstSection .serviceSectionContent p {
        font-size: 1rem;
    }
    .serviceFirstSection .serviceSectionContent h3 {
        font-size: 1.2rem;
    }

    .secondSectionContent {
        width: 21rem;
    }
    .serviceSecondSection .secondSectionContent h4{
        font-size: 1.2rem;
    }
    .serviceSecondSection .secondSectionContent p{
        font-size: 1rem;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .serviceBannerImage {
        height: 28rem;
    }
    .serviceBannerContent {
        top: 17rem;
        left: 20rem;
        font-size: 2.1rem;
        
    }
    .serviceBannerContent h1{
        font-size: 2.7rem;
    }
    .serviceBannerContent p{
        font-size: 1rem;
        width: 35rem;
        word-wrap: break-word;
    }
    .serviceFirstSection .serviceIcons {
        width: 4rem;
    }
    .serviceFirstSection .bgColor {
        margin: 0.4rem 0rem 0.2rem 0rem;;
    }
    .serviceFirstSection .serviceSectionContent {
        padding: 2rem 2rem;
    }
    .serviceFirstSection .serviceSectionContent p {
        font-size: 1.3rem;
    }
    .serviceFirstSection .serviceSectionContent h3 {
        font-size: 1.6rem;
    }

    .secondSectionContent {
        width: 40rem;
    }
    .serviceSecondSection .secondSectionContent h4{
        font-size: 1.2rem;
    }
    .serviceSecondSection .secondSectionContent p{
        font-size: 1rem;
    }
}

@media (min-width: 769px) and (max-width: 900px) {
    .serviceBannerImage {
        height: 31rem;
    }
    .serviceBannerContent {
        top: 18rem;
        left: 20.7rem;
        font-size: 2.1rem;
    }
    .serviceBannerContent h1{
        font-size:3rem;
    }
    .serviceBannerContent p{
        font-size: 1.1rem;
        width: 35rem;
        word-wrap: break-word;
    }
    .serviceFirstSection .serviceIcons {
        width: 5rem;
    }
    .serviceFirstSection .bgColor {
        margin: 1px;
    }
    .serviceFirstSection .serviceSectionContent {
        padding: 6rem 5rem;
    }
    .serviceFirstSection .serviceSectionContent p {
        font-size: 1.2rem;
    }
    .serviceFirstSection .serviceSectionContent h3 {
        font-size: 1.8rem;
    }

    .secondSectionContent {
        width: 50rem;
    }
    .serviceSecondSection .secondSectionContent h4{
        font-size: 1.4rem;
    }
    .serviceSecondSection .secondSectionContent p{
        font-size: 1.1rem;
    }
}
@media (min-width: 901px) and (max-width: 1024px) {
    .serviceBannerImage {
        height: 31rem;
    }
    .serviceBannerContent {
        top: 18rem;
        left: 20.7rem;
        font-size: 2.1rem;
    }
    .serviceBannerContent h1{
        font-size:3rem;
    }
    .serviceBannerContent p{
        font-size: 1.1rem;
        width: 35rem;
        word-wrap: break-word;
    }
    .serviceFirstSection .serviceIcons {
        width: 5rem;
    }
    .serviceFirstSection .bgColor {
        margin: 0px;
    }
    .serviceFirstSection .serviceSectionContent {
        padding: 6rem 5rem;
    }
    .serviceFirstSection .serviceSectionContent p {
        font-size: 1.2rem;
    }
    .serviceFirstSection .serviceSectionContent h3 {
        font-size: 1.8rem;
    }

    .secondSectionContent {
        width: 50rem;
    }
    .serviceSecondSection .secondSectionContent h4{
        font-size: 1.4rem;
    }
    .serviceSecondSection .secondSectionContent p{
        font-size: 1.1rem;
    }
}

@media (min-width: 1025px) and (max-width: 1200px) {
    .serviceBannerImage {
        height: 30rem;
    }
    .serviceBannerContent {
        top: 18rem;
        left: 22rem;
        font-size: 3rem;
    }
    .serviceBannerContent h1{
        font-size: 3rem;
    }
    .serviceBannerContent p{
        font-size: 1.1rem;
        line-height: 1.8rem;
        width: 35rem;
        word-wrap: break-word;
    }
    .secondSectionContent {
        width: 55rem;
    }
    .serviceFirstSection .bgColor {
        margin: 0rem;
    }
}

@media (min-width: 1201px) and (max-width: 1450px) {
    .serviceBannerImage {
        height: 40rem;
    }
    .serviceBannerContent {
        top: 22rem;
        left: 23rem;
        font-size: 3rem;
    }
    .serviceBannerContent h1{
        font-size: 4rem;
    }
    .serviceBannerContent p{
        font-size: 1.1rem;
        width: 35rem;
        word-wrap: break-word;
    }
    .serviceFirstSection .serviceIcons {
        width: 6rem;
    }
    .serviceFirstSection .bgColor {
        margin: 0px;
    }
    .serviceFirstSection .serviceSectionContent {
        padding: 12rem 5rem;
    }
    .serviceFirstSection .serviceSectionContent p {
        font-size: 1.4rem;
    }
    .serviceFirstSection .serviceSectionContent h3 {
        font-size: 2rem;
    }

    .secondSectionContent {
        width: 70rem;
    }
    .serviceSecondSection .secondSectionContent h4{
        font-size: 1.2rem;
    }
    .serviceSecondSection .secondSectionContent p{
        font-size: 1rem;
    }
}

@media (min-width: 1451px) and (max-width: 2600px) {
    .serviceBannerImage {
        height: 46rem;
    }
    .serviceBannerContent {
        top: 26rem;
        left: 28rem;
        width: 44rem;
        font-size: 4rem;
    }
    .serviceBannerContent h1{
        font-size: 5rem;
    }
    .serviceBannerContent p{
        font-size: 1.1rem;
        word-wrap: break-word;
        width: 35rem;
    
    }
    .serviceFirstSection .serviceIcons {
        width: 5rem;
    }
    .serviceFirstSection .bgColor {
        margin: 0px;
    }
    .serviceFirstSection .serviceSectionContent {
        padding: 12rem 5rem;
    }
    .serviceFirstSection .serviceSectionContent p {
        font-size: 1.4rem;
    }
    .serviceFirstSection .serviceSectionContent h3 {
        font-size: 2rem;
    }

    .secondSectionContent {
        width: 70rem;
    }
    .serviceSecondSection .secondSectionContent h4{
        font-size: 1.7rem;
    }
    .serviceSecondSection .secondSectionContent p{
        font-size: 1.1rem;
    }
    .serviceSecondSection .secondSectionContent p:last-child {
        margin-bottom: 0rem;
    }
}