

body {
  background-color: #f6f5f5;
}

.ResetButton,
.ResetButton:first-child:hover,
:not(.ResetButton-check)+.ResetButton:hover {
  background-color: #FDC707 !important;
  border-color: #FDC707 !important;
  color: white;
  border-radius: 2.5rem !important;
}


.HeadElement {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

}

.imgcls {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
}

.image-container {
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 2rem;
  height: 46rem;
  position: relative;
  z-index: 1;


}

.image-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  background: linear-gradient(rgb(23 14 14 / 9%), rgb(53 53 53 / 80%));
}


.HeadText {
  font-family: 'Poppins';
  font-size: 6vw;
  font-weight: bolder;

  color: #FFFFFF;
}

.SubHeadText {
  font-family: 'Poppins';
  font-size: 4vw;
  font-weight: 500;

  color: #FFFFFF;

}

.SubText {
  margin: 3rem 0rem;
  word-wrap: break-word;
  text-overflow: clip;
  text-align: left;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 35px;
  color: #231E28;

}


.learn
/* .learn:first-child:hover,
:not(.learn-check)+.learn:hover  */
{
  background-color: #FDC707;
  border-color: #FDC707;
  color: white;
  float: left;
  margin-left: 12rem;
  border-radius: 2.5rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

.Pagestyle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  padding: 20px;
}

.Paginationstyle {
  display: flex;
  padding: 20px;
  color: transparent;
}

.page-link,
.page-link:hover {
  color: #231E28;
  border: transparent;
  border-color: transparent;
}

.pagination {
  border-radius: 0px;
  line-height: 19px;
  border-color: transparent;
  color: transparent;
  background-color: transparent;
  --bs-pagination-bg: transparent;
}


.carousel-indicators {
  justify-content: left;
  margin-left: 10%;
  margin-bottom: 2rem;
}

.carousel-indicators [data-bs-target] .active, .carousel-indicators .active {
  background-color: #FD5F07;
}

.carousel-indicators [data-bs-target] {
  width: 45px;
  background-color: #FD5F07;
}

.carousel-control-prev-icon {
  background-image: url('../../assets/Backarrow.svg');

}

.carousel-control-next-icon {
  background-image: url('../../assets/Frontarrow.svg');

}

.carousel-caption {
  position: relative;
  left: -2%;
  padding-top: 10.25rem;
}

.text-danger {
  color: white !important;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-size: 3vw;
  line-height: 120%;
  align-items: center;
  justify-content: center;

}

.head-content {
  z-index: 3;
  max-width: 1500px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
}

.cardstyle {
  justify-content: start;
  height: 365px;
}

.cardstyle:hover .card-title,
.cardstyle:hover .card-text,
.cardstyle:hover .card-text>span {
  color: #000;
}

.cardstyle:hover .card-text {
  border-bottom: 2px solid #fff;
}
.OpeningText {
  text-align: left;
  line-height: 50px;
  font-size: 25px;
}

.Openings {
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
  /* justify-content: space-between; */
  row-gap: 25px;
}

.my-2 {
  background-color: white;
  color: black;
  border-radius: 0;
  border-color: #FFFFFF;
}

.my-2:hover {
  cursor: pointer;
  background-color: #0492B1;
  color: #fff;
  border-radius: 0;
  border-color: #0492B1;
}


.ApplyButton {
  background-color: transparent;
  border-color: transparent;
  color: #FDC707 !important;
  border-radius: 0;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  /* margin-top: 5px; */
  width: 180px;
  /* height: 60px; */
  text-align: left;
  padding-left: 0rem;
}

.ApplyButton:first-child:hover,
:not(.ApplyButton-check)+.ApplyButton:hover {
  background-color: transparent;
  border-color: transparent;
  color: #FDC707;
}

.Exp {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 26px;
  color: #231E28;

}


.card-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-size: 23px;
  line-height: 26px;
  text-align: left;
  color: #231E28;
  padding: 10px;
  height: 72px;
}

.card-title:hover {
  color: #fff;
}

.card-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  color: #868C92;
  text-align: left;
  padding: 5px;
  border-bottom: 2px solid #0492B1;
}

.card-text:hover,
.card-text:active {
  color: #fff;
}

.card-body{
  height: 100%;
  justify-content: start;
  .ApplyButton {
    margin-top: auto;
  }
}

.card-body .card-text>span {
  font-weight: bolder;
  color: black;
  font-size: 15px;
}

/* .card>hr{
  border: 1px solid #0492B1;
  margin-left: 15px;
  margin-right: 15px;
} */

.card>hr:hover {

  border: 1px solid #fff;
  margin-left: 15px;
  margin-right: 15px;
}

.hr {
  margin: 0rem;
}

.custom-tag {
  max-width: 100%;
  height: 700px;
  background: #0492B1;
  background-attachment: fixed;
  background-position: top right; 
}

.careerPageTexts img{
  position: absolute;
   right: 0
}
@media screen and (min-width: 320px) {
  .image-container {
    height: 450px;
  }

  .head-content {
    margin-left: 0px;
  }

  .HeadText {
    font-size: 8vw;
    margin-top: 150px;
  }

  .SubHeadText {
    font-size: 5vw;
  }

  .SubText {
    font-size: 16px;
    line-height: 30px;
    margin-left: 3px;
  }
  .OpeningText {
    margin-left: 3px;
  }

  .card-body .card-text>span {
    font-size: 15px;
  }
  .custom-tag {
    height: 29rem;
  }
  .careerPageTexts img {
    width: 95%;
    height: auto;
    top: 0;
  }
  .careerPageTexts .carousel-caption {
    left: -20%;
    padding-top: 10.25rem;
    top: 150px;
  }
  .careerPageTexts .learn {
    margin-left: 5%;
    margin-top: 40%;
  }
  .careerPageTexts .carousel-caption h3 {
    font-size: 1.6rem;
    margin-left: 13%;
    margin-top: -15%;
  }
  .careerPageTexts .carousel-caption p {
    font-size: 1rem;
    margin-left: 21%;
  }
  .careerBlog .carousel-indicators {
    margin-left: 2rem;
  }
}

@media (min-width: 321px) and (max-width: 480px) {
  /* .image-container {} */

  .head-content {
    margin-left: -10px;
  }

  .HeadText {
    font-size: 8vw;
    margin-top: 150px;
  }

  .SubHeadText {
    font-size: 5vw;
  }

  .SubText {
    font-size: 16px;
  }
  .custom-tag {
    height: 34rem;
  }
  .careerPageTexts img {
    width: 365px;
    height: auto;
    top: 0;
  }
  .careerPageTexts .carousel-caption {
    left: -20%;
    padding-top: 10.25rem;
    top: 150px;
  }
  .careerPageTexts .learn {
    margin-left: 9%;
    margin-top: 35%;
  }
  .careerPageTexts .carousel-caption h3 {
    font-size: 2rem;
    margin-left: 17%;
    margin-top: 2%;
  }
  .careerPageTexts .carousel-caption p {
    font-size: 1.2rem;
    margin-left: 22%;
  }
  .careerBlog .carousel-indicators {
    margin-left: 2.5rem;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  /* .image-container {} */

  .head-content {
    margin-left: -240px;
  }

  .HeadText {
    font-size: 5vw;
    margin-top: 150px;
  }

  .SubHeadText {
    font-size: 3vw;
  }

  .custom-tag {
    height: 25rem;
  }
  .careerPageTexts img {
    width: 400px;
    height: auto;
    top: 0;
  }
  .careerPageTexts .carousel-caption {
    position: relative;
    left: -30%;
    padding-top: 6rem;
  }
  .careerPageTexts .learn {
    margin-left: 2rem;
    margin-top: 17%;
  }
  .careerPageTexts .carousel-caption h3 {
    font-size: 2rem;
    margin-left: 1rem;
  }
  .careerPageTexts .carousel-caption p {
    font-size: 1.2rem;
    margin-left: 2.4rem;
  }
  .careerBlog .carousel-indicators {
    margin-left: 2rem;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .image-container {
    height: 500px;
  }

  .head-content {
    margin-left: -330px;
  }

  .HeadText {
    font-size: 5vw;
    margin-top: 150px;
  }

  .SubHeadText {
    font-size: 3vw;
  }

  .SubText {
    font-size: 20px;
  }
  .custom-tag {
    height: 32rem;
  }
  .careerPageTexts img {
    width: 55%;
    height: auto;
    top: 0;
  }
  .careerPageTexts .carousel-caption {
    position: relative;
    /* left: -30%; */
    padding-top: 6rem;
  }
  .careerPageTexts .learn {
    margin-left: 2rem;
    margin-top: 30%;
  }
  .careerPageTexts .carousel-caption h3 {
    font-size: 2.7rem;
    margin-left: 7rem;
  }
  .careerPageTexts .carousel-caption p {
    font-size: 1.5rem;
    margin-left: 7rem;
  }
  .careerBlog .carousel-indicators {
    margin-left: 2rem;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .image-container {
    height: 500px;
  }

  .head-content {
    margin-left: 0px;
    left: 3.1rem;
  }

  .HeadText {
    font-size: 5vw;
    margin-top: 150px;
  }

  .SubHeadText {
    font-size: 3vw;
  }
  .custom-tag {
    height: 535px;
  }
  .careerPageTexts img {
    width: 55%;
    height: auto;
    top: 0;
  }
  .careerPageTexts .carousel-caption {
    position: relative;
    /* left: -30%; */
    padding-top: 6rem;
  }
  .careerPageTexts .learn {
    margin-left: 2rem;
    margin-top: 30%;
  }
  .careerPageTexts .carousel-caption h3 {
    font-size: 2.7rem;
    margin-left: 7rem;
  }
  .careerPageTexts .carousel-caption p {
    font-size: 1.5rem;
    margin-left: 5rem;
  }
}

@media (min-width: 1201px) and (max-width: 1450px) {
  .image-container {
    height: 40rem;
  }

  .head-content {
    margin-left: -21rem;
  }

  .HeadText {
    font-size: 5rem;
    margin-top: 5rem;
  }

  .SubHeadText {
    font-size: 3rem;
  }
  .custom-tag {
    height: 32rem;
  }
  .careerPageTexts img {
    width: 34rem;
    height: auto;
    top: 0;
  }
  .careerPageTexts .carousel-caption {
    position: relative;
    /* left: -30%; */
    padding-top: 6rem;
  }
  .careerPageTexts .learn {
    margin-left: 6rem;
    margin-top: 8rem;
  }
  .careerPageTexts .carousel-caption h3 {
    font-size: 2.7rem;
    margin-left: 8rem;
  }
  .careerPageTexts .carousel-caption p {
    font-size: 1.5rem;
    margin-left: 8rem;
  }
  .SubText {
    margin-left: 1rem;
  }
  .OpeningText {
    margin-left: 1rem;
  }
  .Openings{
    margin-left: 0rem; 
  }
  .careerBlog .carousel-indicators {
    margin-left: 7rem;
  }
}

@media (min-width: 1451px) and (max-width: 2600px) {
  .image-container {
    height: 46rem;
  }

  .head-content {
    margin-left: -24rem;
  }

  .HeadText {
    font-size: 5rem;
    margin-top: 5rem;
  }

  .SubHeadText {
    font-size: 2.4rem;
  }

  .SubText{
    font-size: 1rem;
    line-height: 2rem;
    margin-left: -0.7rem;
  }
  .OpeningText {
    margin-left: -0.7rem;
  }
  .Openings{
    margin-left: -1.4rem; 
  }
  .custom-tag {
    height: 35rem;
  }
  .careerPageTexts img {
    width: 37rem;
    height: auto;
    top: 0;
  }
  .careerPageTexts .carousel-caption {
    position: relative;
    /* left: -30%; */
    padding-top: 6rem;
  }
  .careerPageTexts .learn {
    margin-left: 7rem;
    margin-top: 8rem;
  }
  .careerPageTexts .carousel-caption h3 {
    font-size: 3.1rem;
    margin-left: 10rem;
  }
  .careerPageTexts .carousel-caption p {
    font-size: 1.6rem;
    margin-left: 8rem;
  }
  .careerBlog .carousel-indicators {
    margin-left: 7rem;
  }
}